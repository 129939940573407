import { useState } from "react";
import { projectAuth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

import { useHistory } from "react-router-dom";

//! this hook is used within the useLogintoken hook
//! will route the user to the user-portal page on successful login
export const useLogin = () => {
  //states for error, pending and data
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  //auth context dispatch
  const { dispatch } = useAuthContext();
  //history
  const history = useHistory();

  const fire_login = async (email: string, userToken: string, routeToPortal: boolean = true) => {
    setIsPending(true);

    //try to sign the user out
    try {
      const res: any = await projectAuth.signInWithCustomToken(userToken);
      await res.user.updateProfile({ displayName: email });
      //await res.user.updateEmail(email)
      dispatch({ type: "LOGIN", payload: res.user });
      //update state
      setIsPending(false);
      setError(null);
      if (routeToPortal) history.push("/user-portal");
    } catch (err: any) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  return { fire_login, error, isPending };
};

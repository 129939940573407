//react bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
//custom components & styles
import styles from "./global.module.css";
import { useEffect } from "react";

export default function ReportIssues() {
  //set title of page
  useEffect(() => {
    let title = "Report Issues/Concerns - Fellowships - NYPL";
    if (process.env.REACT_APP_dev) {
      title = process.env.REACT_APP_baseTitle + " | " + title;
    }
    document.title = title;
    //focus on heading
    document.getElementById("heading")?.focus();
  }, []);

  return (
    <Container>
      <Row>
        <h1
          tabIndex={-1}
          id="heading"
          className={`display-4 ${styles.page_header}`}
        >
          Report Issues/Concerns
        </h1>
      </Row>
      <Row>
        <Card className="shadow-lg m-1">
          <Card.Header className="lead text-center bg-white">
            If you have any questions or concerns. Please email:{" "}
            <a className="text-nypl-danger" href="mailto:fellowships@nypl.org">
              fellowships@nypl.org
            </a>
          </Card.Header>
          <Card.Body className="lead">
            <p>
              If you are contacting us regarding technical issues, please
              provide the following information in your email:
            </p>
            <ul>
              <li>Your full name.</li>
              <li>
                The email address associated with your NYPL Fellowships Portal
                account.{" "}
                <ul>
                  <li>Fellowship name + Cycle (if applicable).</li>
                </ul>
              </li>
              <li>Detailed description of the issue.</li>
              <li>
                Device Name and Version.{" "}
                <span className="text-primary">
                  (E.g. Apple MacBook Air 2022)
                </span>
              </li>
              <li>
                Browser and Browser Version.{" "}
                <span className="text-primary">
                  (E.g. Google Chrome Version 113.0.5672.92 (Official Build)
                  (arm64))
                </span>
                <ul>
                  <li>
                    <a
                      href="https://www.whatismybrowser.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to find your browser version.
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Operating System.{" "}
                <span className="text-primary">(E.g. Windows 11)</span>
              </li>
              <li>Screen shot(s) of the issue.</li>
            </ul>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

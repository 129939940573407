import { useState, Fragment, Suspense, lazy } from "react";

//Custom Components
import { CardTable, Tr } from "../../../components/Table/CardTable";
import { EditButton } from "../../../components/Buttons";
import { BannerAlert } from "../../../components/Alerts";
import { RowLoader } from "../../../components/Loaders";

//lazy load the component
const EditContactModal = lazy(() => import("./EditContactModal"));

type ContactInformationProps = {
  data: Record<string, any>;
  pending: boolean;
  incomplete: boolean;
};

export default function ContactInformation({
  data: contact_data,
  pending,
  incomplete,
}: ContactInformationProps): JSX.Element {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [showSuccess] = useState(sessionStorage.getItem("updatedContactInfo"));

  //called from Edit Modal when info is saved successfully
  function showSuccessCallback() {
    sessionStorage.setItem("updatedContactInfo", "true");
    window.location.reload();
  }

  return (
    <CardTable
      incomplete={incomplete}
      section_title="CONTACT INFORMATION"
      side_button={
        <EditButton
          disabled={!!pending}
          onClick={() => setEditing(true)}
          aria-label="Edit Contact information via edit contact form"
        />
      }
      alert={
        !!showSuccess && (
          <BannerAlert
            variant="success"
            message="Successfully updated contact information"
            removeAlert={() => sessionStorage.removeItem("updatedContactInfo")}
          />
        )
      }
    >
      <Fragment>
        <Suspense fallback={<RowLoader />}>
          {!pending ? (
            <Fragment>
              <EditContactModal
                contact_data={contact_data}
                show={isEditing}
                onHide={() => setEditing(false)}
                successCallback={showSuccessCallback}
              />
              <Tr head="First Name:" data={contact_data?.first_name} />
              <Tr head="Last Name:" data={contact_data?.last_name} />
              <Tr head="Email Address:" data={contact_data?.primary_email} />
              <Tr head="Phone Number:" data={contact_data?.phone_number} />
              <Tr
                head="Address Line 1:"
                data={
                  contact_data?.permanent_address
                    ? contact_data?.permanent_address.street
                    : ""
                }
              />
              <Tr
                head="Address Line 2:"
                data={
                  contact_data?.permanent_address
                    ? contact_data?.permanent_address.line_two
                    : ""
                }
              />
              <Tr
                head="City:"
                data={
                  contact_data?.permanent_address
                    ? contact_data?.permanent_address.usercity
                    : ""
                }
              />
              <Tr
                head="Zip Code:"
                data={
                  contact_data?.permanent_address
                    ? contact_data?.permanent_address.zip
                    : ""
                }
              />
              <Tr head="State:" data={contact_data?.state} />
              <Tr head="Country:" data={contact_data?.country} />
            </Fragment>
          ) : (
            <RowLoader />
          )}
        </Suspense>
      </Fragment>
    </CardTable>
  );
}

import {
  Fragment,
  useState,
  useContext,
  useEffect,
  Suspense,
  lazy,
} from "react";
//styles and custom components
import { EditButton, AddButton } from "../../../components/Buttons";
import { RowLoader, PortalCardFallback } from "../../../components/Loaders";
import { BannerAlert } from "../../../components/Alerts";
import { HorizontalTable, Th, Td } from "../../../components/Table/HTable";
// import AddProgramModal from "./AddProgramModal";
// import EditProgramModal from "./EditProgramModal";
// import DeleteProgramModal from "./DeleteProgramModal";
//react-bootstrap
import Button from "react-bootstrap/Button";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
//context
import { programDataContext } from "../MyProfile";
//lazy loading
const AddProgramModal = lazy(() => import("./AddProgramModal"));
const EditProgramModal = lazy(() => import("./EditProgramModal"));
const DeleteProgramModal = lazy(() => import("./DeleteProgramModal"));

export default function ProgramFellowship({
  user_email,
}: {
  user_email: string;
}): JSX.Element {
  //state to show Add modal
  const [isAdding, setIsAdding] = useState<boolean>(false);
  //state to show Edit modal
  const [isEditing, setEditing] = useState(false);
  //state to show Delete modal
  const [isDeleting, setDeleting] = useState(false);
  //state that stores row data
  const [rowData, setRowData] = useState<null | Record<string, any>>(null);
  //state for successful add
  const [showAddSuccess] = useState(sessionStorage.getItem("addedProgram"));
  //state for successful edit
  const [showEditSuccess] = useState(sessionStorage.getItem("updatedProgram"));
  //state for successful delete
  const [showDeleteSuccess] = useState(sessionStorage.getItem("deletedProgram"));

  const { data, isPending, error } = useContext(programDataContext);
  const [progData, setProgData] = useState<any>(data || []);

  useEffect(() => {
    setProgData(data || []);
  }, [data]);

  function showModal(
    row_data: null | Record<string, any> = null,
    action: string
  ) {
    switch (action) {
      case "add":
        setIsAdding(true);
        break;
      case "edit":
        if (row_data) {
          setRowData(row_data);
          setEditing(true);
        }
        break;
      case "delete":
        if (row_data) {
          setRowData(row_data);
          setDeleting(true);
        }
        break;
      default:
        return;
    }
  }

  function showSuccessCallback(action: string) {
    /*function that shows success message if adding, editing or deleting is successful */
    sessionStorage.clear();
    switch (action) {
      case "add":
        sessionStorage.setItem("addedProgram", "true");
        window.location.reload();
        break;
      case "edit":
        sessionStorage.setItem("updatedProgram", "true");
        window.location.reload();
        break;
      case "delete":
        sessionStorage.setItem("deletedProgram", "true");
        window.location.reload();
        break;
      default:
        return;
    }
  }

  return (
    <Suspense fallback={PortalCardFallback}>
      <AddProgramModal
        show={isAdding}
        onHide={() => setIsAdding(false)}
        user_email={user_email}
        successCallback={() => showSuccessCallback("add")}
      />
      <HorizontalTable
        no_data={!isPending && progData.length === 0}
        section_title="PROGRAM/FELLOWSHIP RESEARCH HISTORY"
        side_button={
          <AddButton
            aria-label="Add PROGRAM/FELLOWSHIP RESEARCH HISTORY via form"
            onClick={() => {
              showModal(null, "add");
            }}
            disabled={!!isPending}
          />
        }
        thead={
          <Fragment>
            <Th children="Program/Fellowship Name" />
            <Th children="Date" />
          </Fragment>
        }
        error={
          <Fragment>
            {showAddSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully added program/fellowship research history"
                removeAlert={() => sessionStorage.removeItem("addedProgram")}
              />
            )}
            {showEditSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully updated program/fellowship research history"
                removeAlert={() => sessionStorage.removeItem("updatedProgram")}
              />
            )}
            {showDeleteSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully deleted program/fellowship research history"
                removeAlert={() => sessionStorage.removeItem("deletedProgram")}
              />
            )}
            {error && (
              <BannerAlert
                variant="danger"
                message={error}
                title="Failed to load program/fellowship research history"
              />
            )}
          </Fragment>
        }
      >
        <Fragment>
          {rowData && (
            <Fragment>
              <EditProgramModal
                show={isEditing}
                onHide={() => setEditing(false)}
                user_email={user_email}
                rowData={rowData}
                successCallback={() => showSuccessCallback("edit")}
              />
              <DeleteProgramModal
                show={isDeleting}
                onHide={() => setDeleting(false)}
                user_email={user_email}
                rowData={rowData}
                successCallback={() => showSuccessCallback("delete")}
              />
            </Fragment>
          )}

          <tbody>
            {!isPending ? (
              <Fragment>
                {progData.map((item: Record<string, any>) => (
                  <tr key={item.urlsafekey}>
                    <Td first> {item.program_name} </Td>
                    <Td> {item.program_date} </Td>
                    <Th>
                      <div className="d-flex h-100">
                        <EditButton
                          aria-label="Edit PROGRAM/FELLOWSHIP RESEARCH HISTORY via form"
                          disabled={!item}
                          className="my-auto me-2"
                          onClick={() => showModal(item, "edit")}
                          size="sm"
                        />
                        <Button
                          aria-label="Delete PROGRAM/FELLOWSHIP RESEARCH HISTORY"
                          disabled={!item}
                          variant="outline-nypl-danger"
                          className="text-nowrap rounded-pill"
                          onClick={() => showModal(item, "delete")}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faTrash} /> Delete
                        </Button>
                      </div>
                    </Th>
                  </tr>
                ))}
              </Fragment>
            ) : (
              <RowLoader />
            )}
          </tbody>
        </Fragment>
      </HorizontalTable>
    </Suspense>
  );
}

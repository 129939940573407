import { useEffect, useState } from "react";
import { useLogin } from "./useLogin";

const useLoginToken = () => {
  //API Endpoint
  const url = process.env.REACT_APP_baseURL + "api/check-user/";
  //states for error, pending and data
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState<any>(null);
  //login hook
  const { fire_login, error: Login_error, isPending: fire_isPending } = useLogin();


  function setErrMsg(e: any) {
    if (e.name === "AbortError") {
      console.log("fetch aborted");
    } else {
      setIsPending(false);
      setError(e.message);
    }
  }

  //function that gets called when user logs in via login form
  async function login(email: string, password: string, routeToPortal: boolean = true) {
    setError("");
    setIsPending(true);

    try {
      const abortCont = new AbortController();
      const userPayload = {
        user_email: email,
        password: password,
      };
      //check if the user exists
      fetch(
        url,
        {
          signal: abortCont.signal,
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userPayload),
        }
      )
        .then((response) => {
          //if the response is not ok => parse & set error
          if (!response.ok) {
            response
              .clone()
              .text()
              .then((err) => {
                const error_obj = JSON.parse(err);
                const default_error = "There was an error logging in. Please try again later.";
                if (error_obj.message) {
                  switch (error_obj.message) {
                    case "Applicant does not exist":
                      throw Error("Invalid email or password");
                    default:
                      throw Error(error_obj.message);
                  }
                } else {
                  throw Error(default_error);
                }
              }).catch(setErrMsg);
            setIsPending(false);
          }
          // if an invalid response is returned => continue
          return response.json();
        }).catch(setErrMsg)
        .then((data) => {
          const userToken = data.data;
          //if the user is invalid => throw error
          if (!userToken)
            throw Error("Invalid username or password");
          //else => login the user
          fire_login(email, userToken, routeToPortal);
          //if there is an error in the login => throw error
          if (Login_error)
            throw Error(Login_error);
          //if no error and not pending => set data + reset error and pending
          if (!fire_isPending) {
            setData(data);
            setIsPending(false);
            setError("");
          }
        })
        .catch(setErrMsg);
      //stop the fetch if the user click on different page
      return () => abortCont.abort();
      //if there is an error => set error and reset pending  
    } catch (err: any) {
      if (!isCancelled) {
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  }
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { login, error, isPending, data };
};

export default useLoginToken;
import {
  Fragment,
  useState,
  useContext,
  useEffect,
  Suspense,
  lazy,
} from "react";
//styles and custom components
import { EditButton, AddButton } from "../../../components/Buttons";
import { RowLoader, PortalCardFallback } from "../../../components/Loaders";
import { BannerAlert } from "../../../components/Alerts";
import { HorizontalTable, Th, Td } from "../../../components/Table/HTable";
//react-bootstrap
import Button from "react-bootstrap/Button";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
//context
import { eduDataContext } from "../MyProfile";
//lazy load the component
const EditEduBackModal = lazy(() => import("./EditEduBackModal"));
const DeleteEducationModal = lazy(() => import("./DeleteEducationModal"));
const AddEducationModal = lazy(() => import("./AddEducationModal"));

export default function EducationalBackground({
  user_email,
  incomplete,
}: {
  user_email: string;
  incomplete: boolean;
}) {
  //state to show Add modal
  const [isAdding, setIsAdding] = useState<boolean>(false);
  //state to show Edit modal
  const [isEditing, setEditing] = useState(false);
  //state to show Delete modal
  const [isDeleting, setDeleting] = useState(false);
  //state that stores row data
  const [rowData, setRowData] = useState(null);
  //state for successful add
  const [showAddSuccess] = useState(sessionStorage.getItem("addedEducation"));
  //state for successful edit
  const [showEditSuccess] = useState(sessionStorage.getItem("updatedEducation"));
  //state for successful delete
  const [showDeleteSuccess] = useState(
    sessionStorage.getItem("deletedEducation")
  );

  const { data, isPending, error } = useContext(eduDataContext);
  const [eduData, setEduData] = useState<any>(data || []);

  useEffect(() => {
    setEduData(data || []);
  }, [data]);

  const showModal = (row_data = null, action: string) => {
    switch (action) {
      case "add":
        setIsAdding(true);
        break;
      case "edit":
        if (row_data) {
          setRowData(row_data);
          setEditing(true);
        }
        break;
      case "delete":
        if (row_data) {
          setRowData(row_data);
          setDeleting(true);
        }
        break;
      default:
        return;
    }
  };

  function showSuccessCallback(action: string) {
    /*function that shows success message if adding, editing or deleting is successful */
    sessionStorage.clear();
    switch (action) {
      case "add":
        sessionStorage.setItem("addedEducation", "true");
        window.location.reload();
        break;
      case "edit":
        sessionStorage.setItem("updatedEducation", "true");
        window.location.reload();
        break;
      case "delete":
        sessionStorage.setItem("deletedEducation", "true");
        window.location.reload();
        break;
      default:
        return;
    }
  }

  return (
    <Suspense fallback={PortalCardFallback}>
      <AddEducationModal
        show={isAdding}
        onHide={() => setIsAdding(false)}
        successCallback={() => showSuccessCallback("add")}
        user_email={user_email}
      />
      <HorizontalTable
        no_data={!isPending && eduData.length === 0}
        incomplete={incomplete}
        section_title="EDUCATIONAL BACKGROUND"
        side_button={
          <AddButton
            aria-label="Add educational background via add education form"
            onClick={() => showModal(null, "add")}
            disabled={!!isPending}
          />
        }
        thead={
          <Fragment>
            <Th children="Institution Name" />
            <Th children="Major/Discipline" />
            <Th children="Degree" />
            <Th children="Graduation Date" />
          </Fragment>
        }
        error={
          <Fragment>
            {showAddSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully added educational background"
                removeAlert={() => sessionStorage.removeItem("addedEducation")}
              />
            )}
            {showEditSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully updated educational background"
                removeAlert={() => sessionStorage.removeItem("updatedEducation")}
              />
            )}
            {showDeleteSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully deleted educational background"
                removeAlert={() => sessionStorage.removeItem("deletedEducation")}
              />
            )}
            {error && (
              <BannerAlert
                variant="danger"
                message={error}
                title="Failed to load educational background"
              />
            )}
          </Fragment>
        }
      >
        <Fragment>
          {rowData && (
            <Fragment>
              <DeleteEducationModal
                show={isDeleting}
                onHide={() => setDeleting(false)}
                rowData={rowData}
                user_email={user_email}
                successCallback={() => showSuccessCallback("delete")}
              />
              <EditEduBackModal
                show={isEditing}
                onHide={() => setEditing(false)}
                rowData={rowData}
                successCallback={() => showSuccessCallback("edit")}
              />
            </Fragment>
          )}
          <tbody>
            {!isPending ? (
              <Fragment>
                {eduData.map((item: any) => (
                  <tr key={item.urlsafekey}>
                    <Td first> {item.institution_name} </Td>
                    <Td> {item.major} </Td>
                    <Td> {item.degree} </Td>
                    <Td> {item.graduation_date} </Td>
                    <Th>
                      <div className="d-flex h-100">
                        <EditButton
                          aria-label="Edit educational background via edit education form"
                          disabled={!item}
                          className="my-auto me-2"
                          onClick={() => showModal(item, "edit")}
                          size="sm"
                        />
                        {eduData.length > 1 && (
                          <Button
                            aria-label="Delete educational background"
                            disabled={!item || eduData.length === 1}
                            hidden={eduData.length === 1}
                            variant="outline-nypl-danger"
                            className="text-nowrap rounded-pill"
                            onClick={() => showModal(item, "delete")}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faTrash} /> Delete
                          </Button>
                        )}
                      </div>
                    </Th>
                  </tr>
                ))}
              </Fragment>
            ) : (
              <RowLoader />
            )}
          </tbody>
        </Fragment>
      </HorizontalTable>
    </Suspense>
  );
}

import {
  useContext,
  useEffect,
  useState,
  createContext,
  Suspense,
  lazy,
} from "react";
//User Profile Data Components
import ContactInformation from "./ContactInformation/ContactInformation";
import InstitutionalAffiliations from "./InstitutionalAffiliations/InstitutionalAff";
import EducationalBackground from "./EducationalBackground/EducationalBackground";
import CurriculumVitae from "./CurriculumVitae/CurriculumVitae";
import ProgramFellowship from "./ProgramFellowship/ProgramFellowship";
import PublicationHistory from "./PublicationHistory/PublicationHistory";
// import AccountSettings from "./AcccountSettings/changeLogin";
//UI Components and Styles
import styles from "../global.module.css";
import { BannerAlert, IncompleteAccountAlert } from "../../components/Alerts";
import { CardFallback } from "../../components/Loaders";
//react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//context
import { UserDataContext } from "../../layouts/userPortal/UserPortal";
import useFetchDatastore from "../../hooks/useFetchDatastore";

//lazy loading
const AccountSettings = lazy(() => import("./AccountSettings/AccountSettings"));
//contexts
export const eduDataContext = createContext<any>(null);
export const programDataContext = createContext<any>(null);
export const pubDataContext = createContext<any>(null);
export const allDataContext = createContext<any>(null);

export default function MyProfile() {
  //user data from context
  const { data, isPending, error } = useContext(UserDataContext);
  const [incomplete_steps, setIncompleteSteps] = useState(
    data?.incomplete_steps || []
  );

  //getting user educational background data
  const {
    data: eduData,
    isPending: eduPending,
    error: eduError,
  } = useFetchDatastore(
    process.env.REACT_APP_baseURL + "api/user-educational-background/"
  );

  //getting user program fellowship data
  const {
    data: progData,
    isPending: progPending,
    error: progError,
  } = useFetchDatastore(
    process.env.REACT_APP_baseURL + "api/fellowship-research-history/"
  );

  //getting user publication history data
  const {
    data: pubData,
    isPending: pubPending,
    error: pubError,
  } = useFetchDatastore(
    process.env.REACT_APP_baseURL + "api/user-publication-history/"
  );

  const [userData, setUserData] = useState<any>(
    { data, progData, eduData, pubData } || {}
  );

  //set title of page
  useEffect(() => {
    let title = "My Profile - Fellowships - NYPL";
    if (process.env.REACT_APP_dev) {
      title = process.env.REACT_APP_baseTitle + " | " + title;
    }
    document.title = title;
    //focus on heading
    document.getElementById("heading")?.focus();
  }, []);

  useEffect(() => {
    setIncompleteSteps(data?.incomplete_steps || []);
    setUserData({ data, progData, eduData, pubData } || {});
  }, [data, progData, eduData, pubData]);

  return (
    <Container className="d-flex flex-column">
      {error && (
        <Row className="m-2">
          <BannerAlert
            variant="danger"
            title="failed to load data"
            message={error}
          />
        </Row>
      )}
      {!userData.data?.profile_complete && !isPending && (
        <Row className="m-2">
          <IncompleteAccountAlert />
        </Row>
      )}
      <Row>
        <h1 tabIndex={-1} id="heading" className={`display-4 ${styles.page_header}`}>
          My Profile
        </h1>
      </Row>
      <Row>
        {/* Left Column */}
        <Col>
          {/* Contact Information */}
          <ContactInformation
            data={userData.data}
            pending={isPending}
            incomplete={incomplete_steps.includes(1)}
          />
          {/* Institutional Affiliations */}
          <InstitutionalAffiliations
            data={userData.data}
            pending={isPending}
            incomplete={incomplete_steps.includes(3)}
          />
          {/* Curriculum Vitae */}
          <CurriculumVitae
            data={userData.data}
            pending={isPending}
            incomplete={incomplete_steps.includes(6)}
          />
        </Col>

        {/* Right Column */}
        <Col>
          {/* Educational  Background */}
          <eduDataContext.Provider
            value={{
              data: eduData,
              isPending: eduPending,
              error: eduError,
            }}
          >
            <EducationalBackground
              user_email={data?.primary_email}
              incomplete={incomplete_steps.includes(2)}
            />
          </eduDataContext.Provider>
          {/* ProgramFellowship */}
          <programDataContext.Provider
            value={{
              data: progData,
              isPending: progPending,
              error: progError,
            }}
          >
            <ProgramFellowship user_email={data?.primary_email} />
          </programDataContext.Provider>
          {/* Publication History */}
          <pubDataContext.Provider
            value={{
              data: pubData,
              isPending: pubPending,
              error: pubError,
            }}
          >
            <PublicationHistory user_email={data?.primary_email} />
          </pubDataContext.Provider>
          {/* Account settings */}
          <allDataContext.Provider value={userData}>
            <Suspense fallback={CardFallback}>
              <AccountSettings pending={isPending} />
            </Suspense>
          </allDataContext.Provider>
        </Col>
      </Row>
    </Container>
  );
}

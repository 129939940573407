import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonProps } from "react-bootstrap";
import { Fragment } from "react";

const default_style = {
  whiteSpace: "nowrap" as "nowrap",
};

export function DefaultButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      variant={props.variant || "outline-dark"}
      className={`${props.className || ""} m-1 rounded-pill`}
      style={default_style}
    >
      {props.title ? props.title : props.children}
    </Button>
  );
}

export function EditButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      style={default_style}
      variant="outline-dark"
      className={` ${props.className || ""} rounded-pill my-2`}
    >
      <FontAwesomeIcon icon={faPencil} /> Edit
    </Button>
  );
}

export function AddButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      style={default_style}
      variant="outline-nypl-primary"
      className={` ${props.className || ""} rounded-pill my-2`}
    >
      {props.children || (
        <Fragment>
          <FontAwesomeIcon icon={faPlus} /> Add
        </Fragment>
      )}
    </Button>
  );
}

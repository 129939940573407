import { Fragment } from "react";
import PortalCard from "../Card";
import styles from "./table.module.css";
import { IncompleteWarningAlert } from "../Alerts";
import { Col, Row } from "react-bootstrap";

type HTableProps = {
  section_title: string;
  side_button?: JSX.Element;
  thead: JSX.Element;
  children: JSX.Element;
  error: JSX.Element;
  incomplete?: boolean;
  no_data: boolean;
};

export function HorizontalTable({
  section_title,
  side_button,
  thead,
  children,
  error,
  no_data,
  incomplete = false,
}: HTableProps) {
  return (
    <PortalCard
      className={`table-responsive row ${incomplete && "border-danger"}`}
    >
      <Fragment>
        {/* Heading */}
        <Row>
          <Col className="my-auto">
            <h2 className={`${styles.table_title} my-auto text-nypl-danger`}>
              {section_title}
            </h2>
          </Col>
          <Col md="auto" className="my-auto">
            {side_button}
          </Col>
          {incomplete && <IncompleteWarningAlert />}
          <hr aria-hidden style={{ width: "95%" }} className="mx-auto my-3" />
        </Row>
        {/* Error message: */}
        {!!error ? error : null}
        {/* Table: */}
        {no_data ? (
          <p className="text-center text-nypl-danger">
            No data for this section.
          </p>
        ) : (
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                {thead}
                <Th children="Action" />
              </tr>
            </thead>
            {children}
          </table>
        )}
      </Fragment>
    </PortalCard>
  );
}

export const Th = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <th scope="col" className={`${className} ${styles.table_field}`}>
    {children}
  </th>
);

type TdProps = {
  children: React.ReactNode;
  className?: string;
  first?: boolean;
  style?: React.CSSProperties;
};

export function Td({
  children,
  className = "",
  first = false,
  style,
}: TdProps) {
  return (
    <Fragment>
      {!first ? (
        <td
          className={`${styles.table_data} ${className} text-center`}
          style={style}
        >
          {children}
        </td>
      ) : (
        <th
          scope="row"
          className={`${styles.table_data} ${className} text-center`}
          style={style}
        >
          {children}
        </th>
      )}
    </Fragment>
  );
}

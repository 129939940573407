import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import MyProfile from "../pages/MyProfile/MyProfile";
import Home from "../pages/home/Home";
import ResetPassword from "../pages/ResetPassword";

import DashboardLayoutRoute from "./UserPortalLayoutRoute";
import HomeLayoutRoute from "./HomeLayoutRoute";
import ApplicationList from "../pages/MyApplications/ApplicationsList";
import Fellowships from "../pages/Fellowships/Fellowships";
import ReportIssues from "../pages/ReportIssues";

function AdminHome(): JSX.Element {
  if (process.env.REACT_APP_admin_site) {
    window.location.href = process.env.REACT_APP_admin_site;
    return <Redirect to="/home" />;
  }

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="fs-3">
          {" "}
          <span className="text-danger">Oops!</span> Page not found.
        </p>
        <p className="lead">The page you’re looking for doesn’t exist.</p>
        <a href="/home" className="btn btn-primary">
          Go Home
        </a>
      </div>
    </div>
  );
}

export default function Routers() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <HomeLayoutRoute exact path="/home" component={Home} />
        <HomeLayoutRoute
          exact
          path="/reset-password/"
          component={ResetPassword}
        />
        <DashboardLayoutRoute exact path="/user-portal" component={MyProfile} />
        <DashboardLayoutRoute
          exact
          path="/user-portal/my-applications"
          component={ApplicationList}
        />
        <DashboardLayoutRoute
          exact
          path="/user-portal/fellowships"
          component={Fellowships}
        />
        <DashboardLayoutRoute
          exact
          path="/user-portal/report-issues"
          component={ReportIssues}
        />
        {/* /admin-home goes to google.com */}
        <Route exact path="/admin-home" component={AdminHome} />

        <Route path="*">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Router>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
//custom components and styles
import styles from "../../global.module.css";
import { BannerAlert } from "../../../components/Alerts";
import { ButtonLoader } from "../../../components/Loaders";
import { InvalidFeedback } from "../../home/Register/RegistrationComponents";
//react-bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
//hooks
import useUpdateUserInfo from "../../../hooks/useUpdateUserInfo";
//helpers
import { validateCV } from "../../../helpers/validationHelper";
//focus trap
import FocusTrap from "focus-trap-react";

type EditCVModalProps = {
  cv_data: any;
  show: boolean;
  onHide: () => void;
  successCallback: () => void;
};

export default function EditCVModal({
  cv_data,
  show,
  onHide,
  successCallback,
}: EditCVModalProps): JSX.Element {
  //validation state
  const [validated, setValidated] = useState(false);
  ///states for new CV file
  const [CV, setCV] = useState<any>([{ name: "" }]);
  const [filename, setFilename] = useState<string>("");
  const [fileExt, setFileExt] = useState(null);
  const [file, setFile] = useState<string>("");
  const [mimetype, setMimetype] = useState(null);
  //state for validation field
  const [validationError, setValidationError] = useState<string | null>(
    "Please upload a CV"
  );

  //to update the user CV
  const { updateInfo, updateError, isUpdatePending, updateData } =
    useUpdateUserInfo();

  //if the file changes, then set the file

  //when submitting the form
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.currentTarget;

    //if the form is not valid => stop the submit
    if (form.checkValidity() === false) {
      event.stopPropagation();
      const invalidFields = form.querySelectorAll(":invalid");
      const first = invalidFields[0] as HTMLObjectElement;
      alert(first?.validationMessage || "");
      first?.focus();
    }
    //if the form is valid => submit the form & update info
    else if (!validationError) {
      const updateURL = process.env.REACT_APP_baseURL + "api/update-cv/";
      const UpdateObj = {
        filename,
        fileext: fileExt,
        file: file.replace(`data:${mimetype};base64,`, ""),
        mimetype,
      };
      updateInfo(updateURL, UpdateObj);
    }
    setValidated(true);
  }

  //reset states when modal is closed
  function closeModal() {
    setCV([{ name: "" }]);
    setFilename("");
    setFileExt(null);
    setFile("");
    setMimetype(null);
    setValidationError("Please upload a CV");
    onHide();
  }

  //if the data is updated successfully => refresh the page
  useEffect(() => {
    if (CV[0] && CV[0].name) setValidationError(validateCV(CV));
  }, [CV]);
  useEffect(() => {
    let file = CV[0];
    if (!validationError && file && file.name) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      if (!reader.error) {
        reader.onload = () => {
          let filetype = file.name.split(".");
          setFilename(file.name);
          setFileExt(filetype[filetype.length - 1]);
          setFile(reader.result ? reader.result.toString() : "");
          setMimetype(file.type);
        };
      } else {
        setValidationError("Error reading CV file");
      }
    }
    (document.getElementById("EditCV") as HTMLObjectElement)?.setCustomValidity(
      validationError || ""
    );
  }, [validationError]);

  //if the data is updated successfully => refresh the page
  useEffect(() => {
    if (!isUpdatePending && updateData && !validationError) {
      closeModal();
      successCallback();
    }
  }, [isUpdatePending, updateData]);

  return (
    <Modal
      aria-label="edit CV page"
      show={show}
      onHide={closeModal}
      size="lg"
      centered
      backdrop="static"
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: false,
        }}
      >
        <div>
          <Modal.Header closeButton closeLabel="close edit CV page">
            <Modal.Title as="h2">Uploading new CV</Modal.Title>
          </Modal.Header>
          <Modal.Body as={Container}>
            {updateError && (
              <Row>
                <Col>
                  <BannerAlert
                    variant="danger"
                    title="Error updating data"
                    message={updateError}
                  />
                </Col>
              </Row>
            )}
            <Form
              onSubmit={handleSubmit}
              aria-label="Edit CV form"
              noValidate
              validated={validated}
            >
              <Row className="justify-content-md-center">
                {/* file input field */}
                <Form.Group
                  className={styles.formGroup}
                  as={Col}
                  controlId="EditCV"
                >
                  <Form.Label className={styles.requiredLabel}>File</Form.Label>
                  <Form.Control
                    type="file"
                    required
                    name="CV file"
                    aria-describedby="EnterCVField"
                    onChange={(e: any) => setCV(e.target.files)}
                    {...(validated &&
                      !!validationError && {
                        "aria-describedby": "IFEnterCVField EnterCVField",
                        "aria-invalid": !!validationError,
                      })}
                    accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .txt"
                  />
                  <Form.Text id="EnterCVField">
                    <small className="fw-bold">Current CV: </small>
                    <small className="fst-italic">
                      {cv_data.file_gcs_obj_name || "None Given"}
                    </small>
                  </Form.Text>
                  <InvalidFeedback id="IFEnterCVField">
                    {validationError}
                  </InvalidFeedback>
                </Form.Group>
              </Row>
              {/* save and cancel buttons */}
              <Modal.Footer>
                <Button
                  aria-label="Close Edit CV Page"
                  size="lg"
                  variant="outline-nypl-danger"
                  className="rounded-pill"
                  onClick={closeModal}
                  disabled={!!isUpdatePending}
                >
                  Close
                </Button>
                <Button
                  aria-label="Save CV"
                  size="lg"
                  className="my-auto rounded-pill"
                  type="submit"
                  variant="outline-nypl-primary"
                >
                  {isUpdatePending ? <ButtonLoader text="Saving" /> : "Save"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </div>
      </FocusTrap>
    </Modal>
  );
}

import { Fragment, useState } from "react";
import styles from "../../../components/Table/table.module.css";
//Custom Components
import { CardTable } from "../../../components/Table/CardTable";
import { DefaultButton, EditButton } from "../../../components/Buttons";
import { RowLoader, ButtonLoader } from "../../../components/Loaders";
import EditCVModal from "./EditCVModal";
import { BannerAlert } from "../../../components/Alerts";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
//custom hooks
import useDownload from "../../../hooks/useDownload";
import useIsBrowser from "../../../hooks/useIsBrowser";

export default function CurriculumVitae({
  data,
  pending,
  incomplete,
}: {
  data: any;
  pending: boolean;
  incomplete: boolean;
}) {
  const isBrowser = useIsBrowser();

  const [isEditing, setEditing] = useState<boolean>(false);

  const { downloadFile, error, downloadPending, previewPending } =
    useDownload();

  function handleDownloadFile(
    cv_key: any,
    FileName: any,
    action?: string | undefined
  ) {
    downloadFile(cv_key, FileName, action);
  }
  const [showSuccess] = useState(sessionStorage.getItem("updatedCV"));

  function showSuccessCallback() {
    sessionStorage.setItem("updatedCV", "true");
    window.location.reload();
  }

  return (
    <CardTable
      incomplete={incomplete}
      section_title="CURRICULUM VITAE"
      side_button={
        <EditButton
          aria-label="Edit Curriculum Vitae via edit CV form"
          disabled={!!pending}
          onClick={() => setEditing(true)}
        />
      }
      alert={
        showSuccess && (
          <BannerAlert
            variant="success"
            message="Successfully updated CV"
            removeAlert={() => sessionStorage.removeItem("updatedCV")}
          />
        )
      }
    >
      <Fragment>
        {!pending ? (
          <Fragment>
            <EditCVModal
              cv_data={data}
              show={isEditing}
              onHide={() => setEditing(false)}
              successCallback={showSuccessCallback}
            />
            {error && (
              <tr>
                <th colSpan={100}>
                  <BannerAlert
                    variant="danger"
                    title="Error Downloading File"
                    message={error}
                  />
                </th>
              </tr>
            )}
            <tr>
              <th scope="row" className={styles.table_field} align="center">
                File Name:
              </th>
              <td className={styles.table_data}>
                <p
                  className={`${styles.table_data} ${
                    !data.file_gcs_obj_name && "text-nypl-danger"
                  }`}
                >
                  {data.file_gcs_obj_name || "No CV"}
                </p>

                {isBrowser && (
                  <DefaultButton
                    aria-label="Open CV in new tab"
                    disabled={!data.file_gcs_obj_name || previewPending}
                    size="sm"
                    onClick={() =>
                      handleDownloadFile(
                        data.cv_key,
                        data.file_gcs_obj_name,
                        "preview"
                      )
                    }
                  >
                    {previewPending ? (
                      <ButtonLoader text="Loading" />
                    ) : (
                      <Fragment>
                        <FontAwesomeIcon icon={faEye} /> Open
                      </Fragment>
                    )}
                  </DefaultButton>
                )}

                <DefaultButton
                  aria-label="Download CV"
                  disabled={!data.file_gcs_obj_name || downloadPending}
                  size="sm"
                  onClick={() =>
                    handleDownloadFile(data.cv_key, data.file_gcs_obj_name)
                  }
                >
                  {downloadPending ? (
                    <ButtonLoader text="Loading" />
                  ) : (
                    <Fragment>
                      <FontAwesomeIcon icon={faDownload} /> Download
                    </Fragment>
                  )}
                </DefaultButton>
              </td>
            </tr>
          </Fragment>
        ) : (
          <RowLoader />
        )}
      </Fragment>
    </CardTable>
  );
}

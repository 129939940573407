import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef, Fragment } from "react";
import { Alert, AlertProps, Button, Card, Col } from "react-bootstrap";

type AlertButtonProps = {
  variant: string;
  onClick: () => void;
};

const AlertButton = ({ variant, onClick }: AlertButtonProps): JSX.Element => {
  return (
    <Col className="d-flex">
      <Button
        className="mx-auto my-1"
        variant={variant}
        size="sm"
        onClick={onClick}
      >
        Show Alert
      </Button>
    </Col>
  );
};

type CustomAlertProps = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  variant?: AlertProps["variant"];
  dismissible?: boolean;
  removeAlert?: () => void;
};

export function BannerAlert({
  title,
  message,
  variant = "primary",
  dismissible = true,
  removeAlert,
}: CustomAlertProps): JSX.Element {
  const [show, setShow] = useState(true);
  const alertRef = useRef<HTMLDivElement>(null);

  function onCloseAlert() {
    setShow(false);
    if (removeAlert) {
      removeAlert();
    }
  }

  useEffect(() => {
    if (show && alertRef.current) {
      alertRef.current.focus();
    }
  }, [show]);

  if (show) {
    return (
      <Alert
        variant={variant}
        onClose={onCloseAlert}
        dismissible={dismissible}
        id="banner_alert"
        ref={alertRef}
        tabIndex={-1}
      >
        {title && (
          <Fragment>
            <p className="text-center alert-heading">{title}</p>
            {message && <hr aria-hidden />}
          </Fragment>
        )}
        {message && <p className="text-center mb-0">{message}</p>}
      </Alert>
    );
  }
  return AlertButton({
    variant: `outline-${variant}`,
    onClick: () => setShow(true),
  });
}

export const IncompleteAccountAlert = (): JSX.Element => (
  <Card bg="danger" text="white" className="text-center">
    <Card.Header>
      <strong>Warning: Your account is incomplete.</strong>{" "}
    </Card.Header>
    <Card.Body>
      <Card.Text>
        <FontAwesomeIcon icon={faCircleExclamation} />{" "}
        <strong>
          You won't be able to apply to fellowships until it's completed.
        </strong>
      </Card.Text>
    </Card.Body>
  </Card>
);

export const IncompleteWarningAlert = (): JSX.Element => (
  <Card bg="danger" text="white">
    <Card.Body>
      <Card.Text>
        <FontAwesomeIcon icon={faCircleExclamation} /> <strong>Warning:</strong>{" "}
        This section is incomplete.
      </Card.Text>
    </Card.Body>
  </Card>
);

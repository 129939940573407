import { createContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
//styles and components
import styles from "./userPortal.module.css";
import UserPortalHeader from "../../components/UserPortalHeader";
import NyplFooter from "../../components/Footer/NyplFooter";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
//hooks
import useFetchDatastore from "../../hooks/useFetchDatastore";
//context
export const UserDataContext = createContext<any>(null);

export default function UserPortal({ children }: { children: JSX.Element }) {
  const { data, isPending, error } = useFetchDatastore(
    process.env.REACT_APP_baseURL + "api/user-profile/"
  );

  const location = useLocation();
  const history = useHistory();

  //if the profile is not complete, redirect to the profile page
  useEffect(() => {
    if (!isPending && data && data?.profile_complete === false) {
      //if the route is not the profile page or issues page, redirect to the profile page
      if (
        !["/user-portal", "/user-portal/report-issues"].includes(
          location.pathname
        )
      )
        history.push("/user-portal");
    }
  }, [data, history, isPending, location.pathname]);

  useEffect(() => {
    document.getElementById("heading")?.focus();
  }, [location]);

  return (
    <UserDataContext.Provider value={{ data, isPending, error }}>
      <UserPortalHeader />
      <div className={styles.wrapper} id="main-profile-content">
        {children}
      </div>
      <br />
      <NyplFooter />
      {isPending && <LoadingScreen />}
    </UserDataContext.Provider>
  );
}

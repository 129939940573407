import { useState, useEffect, Fragment, Suspense, lazy } from "react";
//react-bootstrap
import {
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
  FloatingLabel,
} from "react-bootstrap";
//custom components & styles
import PortalCard from "../../components/Card";
import styles from "../global.module.css";
import { FLPlaceholder, Detail, FLHeader } from "./FLComponents";
import { BannerAlert } from "../../components/Alerts";
//hooks
import useFetchDatastore from "../../hooks/useFetchDatastore";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
//html parser
import parse from "html-react-parser";

const ConfirmApply = lazy(() => import("./ConfirmApply"));

export default function Fellowships() {
  //fetch data from datastore
  const { data, isPending, error } = useFetchDatastore(
    process.env.REACT_APP_baseURL + "api/fellowship-list/"
  );

  //state for data
  const [fellowships, setFellowships] = useState<any[]>(data || []);
  const [urlsafeKey, setUrlsafeKey] = useState("");
  //state for search bar
  const [searchTerm, setSearchTerm] = useState("");

  //state for showing Apply modal
  const [showConfirmApply, setShowConfirmApply] = useState(false);

  //set title of page
  useEffect(() => {
    let title = "Fellowships - Fellowships - NYPL";
    if (process.env.REACT_APP_dev) {
      title = process.env.REACT_APP_baseTitle + " | " + title;
    }
    document.title = title;
    //focus on heading
    document.getElementById("heading")?.focus();
  }, []);

  useEffect(() => {
    //set term form each object in data
    if (Array.isArray(data)) {
      for (let obj of data) {
        obj.term = obj.is_long_term
          ? "Long Term"
          : obj.is_middle_term
          ? "Middle Term"
          : "Short Term";
      }
    }
    setFellowships(data);
  }, [data]);

  //filtering fellowship list based on search term
  const filteredList = fellowships.filter((entry: Object) =>
    Object.values(entry).some(
      (val) =>
        typeof val === "string" &&
        val.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  //render
  return (
    <Container>
      {/* Error Message */}
      {error && (
        <Row className="mt-2">
          <BannerAlert title="Error" message={error} variant="danger" />
        </Row>
      )}
      <Row>
        <h1 tabIndex={-1} id="heading" className={`display-4 ${styles.page_header}`}>
          Fellowships
        </h1>
      </Row>

      <Row>
        <PortalCard>
          {/* search bar */}
          <InputGroup className="my-1">
            <FloatingLabel
              controlId="floatingInput"
              label={
                <Fragment>
                  <FontAwesomeIcon icon={faSearch} /> Search by title/description/term
                </Fragment>
              }
            >
              <FormControl
                disabled={isPending}
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </FloatingLabel>
            {searchTerm !== "" && (
              <Button
                aria-label="clear search bar"
                variant="outline-dark"
                onClick={() => setSearchTerm("")}
              >
                <FontAwesomeIcon icon={faTimes} /> Clear
              </Button>
            )}
          </InputGroup>
        </PortalCard>
      </Row>
      {/* confirm apply modal */}
      <Suspense fallback={<FLPlaceholder />}>
        <ConfirmApply
          show={showConfirmApply}
          onHide={() => {
            setShowConfirmApply(false);
            setUrlsafeKey("");
          }}
          urlsafekey={urlsafeKey}
        />
        {/* fellowship list */}

        {filteredList.length > 0 && !isPending ? (
          filteredList.map((item: any, index: number) => {
            return (
              <Row key={index}>
                <PortalCard>
                  {/* Header */}
                  <FLHeader
                    term={item.term}
                    fellowship_name={item.fellowship_name}
                  />
                  {/* Description */}
                  <Row className="my-2">
                    <hr aria-hidden style={{ width: "95%" }} />
                    <p className="lead">
                      {parse(item.fellowship_description.slice(0, 350) + "...")}
                    </p>
                    <hr aria-hidden style={{ width: "95%" }} />
                  </Row>
                  <Row className="justify-content-md-center">
                    {Detail(
                      "Letters of Recommendation",
                      item.required_letters_count,
                      "auto"
                    )}
                    {Detail("Stipend", item.stipend, "auto")}
                    {Detail("Application Deadline", item.end_date, "auto")}
                    <div className="d-flex col justify-content-end">
                      <Button
                        className="rounded-pill text-center"
                        variant="outline-nypl-primary"
                        size="lg"
                        onClick={() => {
                          setUrlsafeKey(item.urlsafekey);
                          setShowConfirmApply(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} /> Apply
                      </Button>
                    </div>
                  </Row>
                </PortalCard>
              </Row>
            );
          })
        ) : filteredList.length === 0 && !isPending ? (
          <Row className="text-center my-4">
            <p className="display-6">No Fellowships Found</p>
          </Row>
        ) : null}
        {isPending && <FLPlaceholder />}
      </Suspense>
    </Container>
  );
}

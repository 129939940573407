import { useState, useEffect, ReactNode, Fragment } from "react";
//react bootstrap
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

//custom
import { ButtonLoader } from "../../components/Loaders";
import { BannerAlert } from "../../components/Alerts";
import { ViewAppPlaceHolder } from "./ApplicationComponents";
//focus trap
import FocusTrap from "focus-trap-react";
//hooks
import useFetchPayload from "../../hooks/useFetchPayload";

export const ViewInfo = ({ content }: { content: ReactNode }) => (
  <Fragment>
    <br />
    <hr />
    <br />
    <Row className="text-center mb-3">
      <h3 className="lead text-decoration-underline">
        What happens after I submit the application?
      </h3>
    </Row>
    <ListGroup className="lead">
      <ListGroup.Item>
        Your application will be reviewed by NYPL staff and a selection
        committee.
      </ListGroup.Item>
      <ListGroup.Item>
        Expected Notification Date:{" "}
        <span className="text-muted">{content}</span>
      </ListGroup.Item>
      <ListGroup.Item>
        You will be notified by email if you were accepted or rejected from the
        program.
      </ListGroup.Item>
      <ListGroup.Item>
        Applications with pending letters of recommendation will be rejected.
      </ListGroup.Item>
      <ListGroup.Item>
        If you have any questions or concerns. Please email{" "}
        <a className="text-nypl-danger" href="mailto:fellowships@nypl.org">
          fellowships@nypl.org.
        </a>
      </ListGroup.Item>
    </ListGroup>
    <br />
  </Fragment>
);

interface ViewApplicationProps {
  application_key: string;
  show: boolean;
  onHide: () => void;
}

export default function ViewApplication({
  application_key,
  show,
  onHide,
}: ViewApplicationProps) {
  //fetch payload for application
  const {
    fetchPayload: fetchApp,
    reset: resetApp,
    updateError: fetchAppError,
    isPending: appIsPending,
    data: appData,
  } = useFetchPayload();

  //fetch payload for resending email
  const {
    fetchPayload: fetchResend,
    reset: resetResend,
    updateError: fetchResendError,
    isPending: resendIsPending,
    successMessage: resendSuccessMessage,
  } = useFetchPayload();
  //for storing application data
  const [app, setApp] = useState<any>(null);
  //for keeping track of which letter is being resent
  const [resetIdx, setResendIdx] = useState<number>(-1);

  useEffect(() => {
    if (Array.isArray(appData) && appData.length > 0) {
      setApp(appData[0]);
    }
  }, [appData]);

  //when the resend data is updated
  useEffect(() => {
    if (!resendIsPending && resetIdx > -1) {
      setResendIdx(-1);
    }
  }, [resendIsPending, resetIdx]);

  const rec_deadline_formatted = new Date(app?.rec_deadline).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
    }
  );

  const deadline_formatted = new Date(app?.deadline).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }
  );

  const pending_recs: any[] = app?.ref_list.filter(
    (ref: any) => !ref.is_uploaded
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      backdrop="static"
      onEnter={() =>
        fetchApp({
          url: process.env.REACT_APP_baseURL + "api/user-applications-light/",
          payload: { urlsafekey: application_key },
        })
      }
      onExit={() => {
        resetApp();
        resetResend();
      }}
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: false,
        }}
      >
        <div>
          {appIsPending ? (
            <ViewAppPlaceHolder />
          ) : (
            <Fragment>
              <Modal.Header
                closeButton
                closeLabel={`close view ${
                  app?.fellowship_name || "fellowship"
                } application page`}
              >
                <Modal.Title as="h2"> {app?.fellowship_name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {fetchAppError && (
                  <BannerAlert
                    variant="danger"
                    message={fetchAppError}
                    title="Error retrieving data"
                  />
                )}
                {fetchResendError && (
                  <BannerAlert
                    variant="danger"
                    message={fetchResendError}
                    title="Error Resending Recommendation Letter Request Email"
                  />
                )}
                {resendSuccessMessage && (
                  <BannerAlert
                    variant="success"
                    message={resendSuccessMessage}
                    title="Success"
                  />
                )}
                <Row>
                  <p>
                    <strong>Application Status:</strong> {app?.status}
                    {!app?.is_open && (
                      <span className="text-nypl-danger">
                        (Application Closed)
                      </span>
                    )}{" "}
                  </p>
                </Row>
                <Row>
                  <p>
                    <strong>Application Deadline:</strong> {deadline_formatted}
                  </p>
                </Row>
                <Row>
                  <p>
                    <strong>Recommendation Letter(s) Status:</strong>
                    {pending_recs?.length > 0 ? (
                      <span className="text-nypl-danger">
                        {" "}
                        Pending {pending_recs.length} Recommendation Letter(s)
                      </span>
                    ) : (
                      <span className="text-success">
                        {" "}
                        All Recommendation Letters Received
                      </span>
                    )}
                  </p>
                </Row>
                <Row>
                  <p>
                    <strong>Recommendation Letter(s) upload deadline:</strong>{" "}
                    {rec_deadline_formatted}
                    {app?.app?.rec_deadline_is_expired && (
                      <span className="text-nypl-danger">
                        {" "}
                        (Deadline Passed)
                      </span>
                    )}{" "}
                  </p>
                </Row>
                <br />
                <hr />
                <br />
                {app?.ref_list.map((ref: any) => (
                  <Row key={ref.ref_id} md="auto" className="mb-2">
                    <Col className="my-auto">
                      <p className="my-auto">
                        <strong>
                          {`Letter of recommendation ${ref.ref_id + 1} `}(
                          {ref.is_uploaded ? (
                            <span className="text-success">Uploaded</span>
                          ) : (
                            <span className="text-nypl-danger">Pending</span>
                          )}
                          ):
                        </strong>{" "}
                        {ref.email_sent ? (
                          <span className="text-nypl-danger">
                            email was sent to {ref.email}{" "}
                          </span>
                        ) : (
                          <span>{`Uploaded by ${ref.email}`}</span>
                        )}
                      </p>
                    </Col>
                    {ref.email_sent && !ref.is_uploaded && (
                      <Col>
                        <Button
                          variant="outline-nypl-danger"
                          className="rounded-pill"
                          disabled={
                            app?.rec_deadline_is_expired ||
                            resendIsPending
                          }
                          onClick={() => {
                            setResendIdx(ref.ref_id);
                            fetchResend({
                              url:
                                process.env.REACT_APP_baseURL +
                                "api/resend-ref-email/",
                              payload: {
                                app_urlsafekey: app.urlsafekey,
                                ref_urlsafekey: ref.urlsafekey,
                              },
                            });
                          }}
                        >
                          {resendIsPending && resetIdx === ref.ref_id
                            ? ButtonLoader({
                                text: "Resending email",
                              })
                            : "Resend Email"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                ))}
                <ViewInfo content={app?.notification_date} />
              </Modal.Body>
            </Fragment>
          )}
        </div>
      </FocusTrap>
    </Modal>
  );
}

import { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";

import SkipNav from "./skipNav/SkipNav";

export default function NyplHeader() {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://header.nypl.org/dgx-header.min.js";
    script.async = true;
    document.getElementById("Header-Placeholder")!.appendChild(script);
    return () => {
      script.remove();
    };
  }, [location.pathname]);
  return (
    <Fragment>
      <SkipNav id="main-home-content" />
      <div id="Header-Placeholder"></div>
    </Fragment>
  );
}

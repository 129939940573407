import { Route, Redirect } from "react-router-dom";
import { Fragment } from "react";
import UserPortal from "../layouts/userPortal/UserPortal";
import { useAuthContext } from "../hooks/useAuthContext";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";

const DashboardLayoutRoute = ({ component: Component, ...rest }: any) => {
  const { authIsReady, user } = useAuthContext();

  return (
    <Fragment>
      {!authIsReady && <LoadingScreen />}
      {authIsReady && (
        <Route
          {...rest}
          render={(props) => (
            <Fragment>
              {!user && <Redirect to="/home" />}
              {user && (
                <UserPortal>
                  <Component {...props} />
                </UserPortal>
              )}
            </Fragment>
          )}
        />
      )}
    </Fragment>
  );
};

export default DashboardLayoutRoute;

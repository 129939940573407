export function isNullObject(object: Record<string, any>): boolean {
    /*
        returns true if every value is null in object
    */
    return Object.values(object).every(value => value === null);
}

export function isFalsyObject(object: Record<string, any>): boolean {
    /*
        returns true if every value is falsy in object
    */
    return Object.values(object).every(value => !value);
}

export function nullObjectsInArray(array: Record<string, any>[]): boolean {
    /*
        returns true if every value is null in array
    */
    return array.every(value => {
        //remove id attribute from object if it exists
        delete value.id;
        return isNullObject(value);
    });
}

export function allEmptyStrings(array: string[]): boolean {
    /*
        returns true if every value is an empty string in array
    */
    return array.every(value => value === "");
}
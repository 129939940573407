import {
  Fragment,
  useState,
  useContext,
  useEffect,
  Suspense,
  lazy,
} from "react";
//styles and custom components
import { EditButton, AddButton } from "../../../components/Buttons";
import { RowLoader } from "../../../components/Loaders";
import { BannerAlert } from "../../../components/Alerts";
import { HorizontalTable, Th, Td } from "../../../components/Table/HTable";
import { PortalCardFallback } from "../../../components/Loaders";
//react-bootstrap
import Button from "react-bootstrap/Button";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
//context
import { pubDataContext } from "../MyProfile";
//lazy loading
const AddPublicationModal = lazy(() => import("./AddPublicationModal"));
const EditPublicationModal = lazy(() => import("./EditPublicationModal"));
const DeletePublicationModal = lazy(() => import("./DeletePublicationModal"));

export default function PublicationHistory({
  user_email,
}: {
  user_email: string;
}): JSX.Element {
  //state to show Add modal
  const [isAdding, setIsAdding] = useState(false);
  //state to show Edit modal
  const [isEditing, setEditing] = useState(false);
  //state to show Delete modal
  const [isDeleting, setDeleting] = useState(false);
  //state that stores row data
  const [rowData, setRowData] = useState<null | Record<string, any>>(null);
  //state for successful add
  const [showAddSuccess] = useState(sessionStorage.getItem("addedPublication"));
  //state for successful edit
  const [showEditSuccess] = useState(
    sessionStorage.getItem("updatedPublication")
  );

  //state for successful delete
  const [showDeleteSuccess] = useState(
    sessionStorage.getItem("deletedPublication")
  );

  const { data, isPending, error } = useContext(pubDataContext);

  const [pubData, setPubData] = useState<any>(data || []);

  useEffect(() => {
    setPubData(data || []);
  }, [data]);

  function showModal(
    row_data: null | Record<string, any> = null,
    action: string
  ) {
    switch (action) {
      case "add":
        setIsAdding(true);
        break;
      case "edit":
        if (row_data) {
          setRowData(row_data);
          setEditing(true);
        }
        break;
      case "delete":
        if (row_data) {
          setRowData(row_data);
          setDeleting(true);
        }
        break;
      default:
        return;
    }
  }

  function showSuccessCallback(action: string) {
    /*function that shows success message if adding, editing or deleting is successful */
    sessionStorage.clear();
    switch (action) {
      case "add":
        sessionStorage.setItem("addedPublication", "true");
        window.location.reload();
        break;
      case "edit":
        sessionStorage.setItem("updatedPublication", "true");
        window.location.reload();
        break;
      case "delete":
        sessionStorage.setItem("deletedPublication", "true");
        window.location.reload();
        break;
      default:
        return;
    }
  }

  return (
    <Suspense fallback={PortalCardFallback}>
      <AddPublicationModal
        show={isAdding}
        onHide={() => setIsAdding(false)}
        user_email={user_email}
        successCallback={() => showSuccessCallback("add")}
      />
      <HorizontalTable
        no_data={!isPending && pubData.length === 0}
        section_title="PUBLICATION HISTORY"
        side_button={
          <AddButton
            aria-label="Add Publication History via add publication history form"
            onClick={() => setIsAdding(true)}
            disabled={!!isPending}
          />
        }
        thead={
          <Fragment>
            <Th children="Publisher Name" />
            <Th children="Publication Details" />
            <Th children="Publication Date" />
          </Fragment>
        }
        error={
          <Fragment>
            {showAddSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully added publication history"
                removeAlert={() =>
                  sessionStorage.removeItem("addedPublication")
                }
              />
            )}
            {showEditSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully updated publication history"
                removeAlert={() =>
                  sessionStorage.removeItem("updatedPublication")
                }
              />
            )}
            {showDeleteSuccess && (
              <BannerAlert
                variant="success"
                message="Successfully deleted publication history"
                removeAlert={() =>
                  sessionStorage.removeItem("deletedPublication")
                }
              />
            )}
            {error && (
              <BannerAlert
                variant="danger"
                message={error}
                title="Failed to load publication history"
              />
            )}
          </Fragment>
        }
      >
        <Fragment>
          {rowData && (
            <Fragment>
              <EditPublicationModal
                show={isEditing}
                onHide={() => setEditing(false)}
                user_email={user_email}
                rowData={rowData}
                successCallback={() => showSuccessCallback("edit")}
              />
              <DeletePublicationModal
                show={isDeleting}
                onHide={() => setDeleting(false)}
                user_email={user_email}
                rowData={rowData}
                successCallback={() => showSuccessCallback("delete")}
              />
            </Fragment>
          )}

          <tbody>
            {!isPending ? (
              <Fragment>
                {pubData.map((item: Record<string, any>) => (
                  <tr key={item.urlsafekey}>
                    <Td> {item.publisher_name} </Td>
                    <Td first> {item.publication_name} </Td>
                    <Td> {item.publication_date} </Td>
                    <Th>
                      <div className="d-flex h-100">
                        <EditButton
                          aria-label="Edit Publication History via edit publication history form"
                          disabled={!item}
                          className="my-auto me-2"
                          onClick={() => showModal(item, "edit")}
                          size="sm"
                        />
                        <Button
                          aria-label="Delete Publication History"
                          disabled={!item}
                          variant="outline-nypl-danger"
                          className="text-nowrap rounded-pill"
                          onClick={() => showModal(item, "delete")}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faTrash} /> Delete
                        </Button>{" "}
                      </div>
                    </Th>
                  </tr>
                ))}
              </Fragment>
            ) : (
              <RowLoader />
            )}
          </tbody>
        </Fragment>
      </HorizontalTable>
    </Suspense>
  );
}

import { Route } from "react-router-dom";
import HomeDefault from "../layouts/homeDefault/HomeDefault";

export default function HomeLayoutRoute({component: Component, ...rest }:any) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <HomeDefault>
          <Component {...props} />
        </HomeDefault>
      )}
    />
  );
}

// import logo from './logo.svg';
import Routers from "./router/Routers";
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    let title = "NYPL Fellowships";
    if (process.env.REACT_APP_dev) {
      title = process.env.REACT_APP_baseTitle + " | " + title;
    }
    document.title = title;

    //google analytics
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_gtag}`;
    document.head.appendChild(script);

    const gtagScript = document.createElement("script");
    gtagScript.type = "text/javascript";
    gtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.REACT_APP_gtag}');`;
    document.head.appendChild(gtagScript);

    const userAgent = navigator.userAgent;
    const isChrome =
      /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
    const isFirefox = /Firefox/.test(userAgent);

    if (!isChrome && !isFirefox) {
      alert(
        "Warning: You are using an unsupported browser, some features may not work properly.\n\nThis website works best on Google Chrome or Mozilla Firefox. Please consider switching for the best experience."
      );
    }
  }, []);

  return <Routers />;
}

import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { ButtonLoader } from "../../../components/Loaders";
//focus trap
import FocusTrap from "focus-trap-react";
//typeAhead
import { Typeahead } from "react-bootstrap-typeahead";
//! this file contains components and constants used in the registration form

export const StepperStyles = {
  activeTextColor: "#fff",
  completedBgColor: "#dc3545",
  completedTextColor: "#fff",
  inactiveBgColor: "#e9ecef",
  inactiveTextColor: "black",
  size: 45,
  circleFontSize: 16,
  labelFontSize: 16,
  labelColor: "#6c757d",
  borderRadius: "15px",
  fontWeight: "600",
};

export const stepperSteps = [
  { label: "Contact Information", step: 1 },
  { label: "Educational Background", step: 2 },
  { label: "Institutional Affiliation", step: 3 },
  { label: "Research History", step: 4 },
  { label: "Publication History", step: 5 },
  { label: "Upload CV", step: 6 },
  { label: "Review and Submit", step: 7 },
];

export const optionalSteps = [4, 5];

type SwitchButtonsProps = {
  step: number;
  handleStep: (action: "save" | "prev" | "skip", e?: any) => void;
  isRegisterPending?: boolean;
  loading?: boolean;
  loadingMessage?: string;
  edited?: boolean | undefined;
};

export function switchButtons({
  step,
  handleStep,
  isRegisterPending = false,
  loading = false,
  loadingMessage = "Saving",
  edited = undefined,
}: SwitchButtonsProps): JSX.Element | null {
  const skip = optionalSteps.includes(step) && edited === false;

  const previous = () => (
    <Button
      aria-label={`Go to previous step: ${step - 1}`}
      className="rounded-pill"
      variant="primary"
      onClick={() => handleStep("prev")}
    >
      <FontAwesomeIcon icon={faArrowLeft} /> Previous
    </Button>
  );

  const next = () => (
    <Button
      aria-label={
        skip
          ? `skip step and go to ${step + 1}`
          : `Save changes and go to next step: ${step + 1}`
      }
      className="rounded-pill"
      variant="primary"
      {...(!skip ? { type: "submit" } : { onClick: () => handleStep("skip") })}
      disabled={loading}
    >
      {loading ? (
        ButtonLoader({ text: loadingMessage })
      ) : (
        <Fragment>
          {skip ? "Skip Step" : "Next"}{" "}
          <FontAwesomeIcon icon={skip ? faForward : faArrowRight} />
        </Fragment>
      )}
    </Button>
  );
  switch (step) {
    //! Registration button
    case 0:
      return (
        <Col md={4}>
          <Button
            aria-label="Register, create user profile and continue to next step"
            size="lg"
            disabled={isRegisterPending}
            className="rounded-pill  mx-auto w-100"
            variant="outline-nypl-primary"
            type="submit"
          >
            {isRegisterPending ? (
              ButtonLoader({ text: "Creating Account" })
            ) : (
              <Fragment>Register</Fragment>
            )}
          </Button>
        </Col>
      );
    //! Next Button (for step 1)
    case 1:
      return next();
    //! Complete Registration Button for last step
    case stepperSteps.length:
      return (
        <Fragment>
          {previous()}
          <Button
            disabled={isRegisterPending}
            className="rounded-pill"
            variant="outline-success"
            onClick={() => handleStep("save")}
          >
            {isRegisterPending ? (
              ButtonLoader({ text: "Finalizing" })
            ) : (
              <Fragment>
                <FontAwesomeIcon icon={faCheck} /> Complete Registration
              </Fragment>
            )}
          </Button>
        </Fragment>
      );
    //! Next and Previous Buttons for all other steps
    default:
      return (
        <Fragment>
          {previous()}
          {next()}
        </Fragment>
      );
  }
}

type ConfirmCloseModalProps = {
  show: boolean;
  cancel: () => void;
  close: () => void;
};

export function ConfirmCloseModal({
  show,
  cancel,
  close,
}: ConfirmCloseModalProps): JSX.Element {
  return (
    <Modal
      centered
      show={show}
      onHide={cancel}
      size="xl"
      aria-labelledby="exit-conf-title"
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: false,
        }}
      >
        <div>
          <Modal.Header>
            <Modal.Title id="exit-conf-title">
              Are you sure you want to close this form?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>
                Your profile is incomplete; you won't be able to apply to
                fellowships until it's completed.
              </strong>{" "}
              If you wish to complete the form now, click on "Go Back to Form"
              button below.
              <br />
              <br />
              Your account has been created, and{" "}
              <strong>
                you can log in at any time to continue filling out the form
              </strong>
              .
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="rounded-pill"
              variant="nypl-primary"
              onClick={cancel}
            >
              Go Back to Form
            </Button>
            <Button className="rounded-pill" variant="danger" onClick={close}>
              Close Form
            </Button>
          </Modal.Footer>
        </div>
      </FocusTrap>
    </Modal>
  );
}

export const InvalidFeedback = (props: {
  children: React.ReactNode;
  id: string;
}) => (
  <Form.Control.Feedback type="invalid" id={props.id}>
    {props.children}
  </Form.Control.Feedback>
);

export const CustomTypeAhead = (props: any) => {
  return <Typeahead {...props} />;
};

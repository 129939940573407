//!this hook is used to fetch data from the api given an input payload
import { useEffect, useState } from "react";

//! This hook allows for the fetching of data without the user being authenticated

//! optionally if the user is authenticated, the token can be passed in the payload

export default function useFetchPayload() {
  const [isCancelled, setIsCancelled] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [isPending, setIsUpdatePending] = useState(false);
  const [successMessage, setSuccess] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [response, setResponse] = useState<any>(null);

  async function fetchPayload({
    url, payload, token = "", successMessage, method = "post",
  }: {
    //! url: the url to fetch from
    url: string;
    //! payload: the payload to send to the api
    payload: Record<string, any>;
    //! method: the method to use for the fetch request
    method?: string;
    //! token: authorization token if the user is authenticated
    token?: string;
    //! successMessage: the message to display on success
    successMessage?: string;
  }) {
    setIsUpdatePending(true);
    setUpdateError("");
    setSuccess("");
    try {
      const abortCont = new AbortController();
      fetch(url, {
        method: method,
        signal: abortCont.signal,
        headers: {
          authorization: (token || process.env.REACT_APP_admin_key) ?? "",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (!res.ok) {
            //if the response is not valid
            //parse response as object and throw error
            res
              .clone()
              .text()
              .then((err) => {
                let error_obj = JSON.parse(err);
                if (error_obj.message) {
                  setUpdateError(error_obj.message);
                } else {
                  setUpdateError(
                    "There was an error updating your information. Please try again later."
                  );
                }
              }).catch((e) => {
                throw new Error(e);
              }
              );

          }
          setIsUpdatePending(false);
          return res.json();
        }).catch((e) => {
          throw new Error(e);
        })
        .then((data) => {
          setData([data.data]);
          setResponse(data);
          if (data.code === 200)
            setSuccess(successMessage || data.message || "Your information has been updated successfully.");

          return data;
        })
        .catch((e) => {
          if (e.name === "AbortError") {
            console.log("fetch aborted");
          } else {
            setIsUpdatePending(false);
            setUpdateError(e.message);
          }
        });
      return () => abortCont.abort();
    } catch (err: any) {
      if (!isCancelled) {
        console.log(err.message);
        setUpdateError(err.message);
        setIsUpdatePending(false);
      }
    }
  };

  function reset() {
    setIsCancelled(false);
    setUpdateError("");
    setIsUpdatePending(false);
    setSuccess("");
    setData([]);
    setResponse(null);
  }

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { fetchPayload, reset, updateError, isPending, successMessage, data, response };
}

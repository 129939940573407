/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useReducer, useState } from "react";
//react bootstrap components
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
//hooks
import { projectAuth } from "../firebase/config";
import { useIdleTimer } from "react-idle-timer";
//helper
import { msToMin } from "../helpers/dateHelper";
//focus trap
import FocusTrap from "focus-trap-react";

export const AuthContext = createContext<any>(null);

export function authReducer(state: any, action: { type: any; payload?: any }) {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      sessionStorage.setItem("loggedOut", "true");
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { user: action.payload, authIsReady: true };
    default:
      return state;
  }
}
export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });
  //states for the modal
  const [showModal, setShowModal] = useState(false);
  //state for remaining time
  const [remaining, setRemaining] = useState(0);
  const timeout = 15 * 60_000;
  //idle timer hook
  const { getRemainingTime, activate } = useIdleTimer({
    //will log out after 30 minutes
    timeout: timeout,
    //will show the modal 1 minute before the session expires
    promptBeforeIdle: 3 * 60_000,
    //will log out when the user idles for 30 minutes
    onIdle: () => handleLogout(),
    //will show the modal when the session is about to expire
    onPrompt: () => {
      if (state.user) setShowModal(true);
    },
    //delay for when the user is active
    throttle: 500,
  });

  //logout function
  async function handleLogout() {
    if (state.user) {
      await projectAuth.signOut();
      dispatch({ type: "LOGOUT" });
      setShowModal(false);
      if (window.location.pathname === "/home") {
        window.location.reload();
      }
    }
  }

  //will count down the remaining time
  useEffect(() => {
    let interval: number | undefined = undefined;
    if (state.user) {
      interval = window.setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
    }
    return () => {
      if (interval !== undefined) {
        window.clearInterval(interval);
      }
    };
  });

  useEffect(() => {
    const unsub = projectAuth.onAuthStateChanged((user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
      unsub();
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {state.user && (
        <Modal
          show={showModal}
          backdrop="static"
          keyboard={false}
          aria-label="session expiration modal"
          centered
          onEntering={() => {
            //hide other modals
            const otherModals = document.querySelectorAll(
              '.modal.show:not([aria-label="session expiration modal"])'
            );
            for (let e of otherModals) {
              e.classList.remove("show");
            }
          }}
          onExiting={() => {
            //show other modals
            const otherModals = document.querySelectorAll(
              '.modal:not([aria-label="session expiration modal"])'
            );
            for (let e of otherModals) {
              if (!e.classList.contains("show")) e.classList.add("show");
            }
          }}
        >
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
              escapeDeactivates: false,
            }}
          >
            <div>
              <Modal.Header>
                <Modal.Title as="h2">
                  Session Expiring Due to Inactivity
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Your session will expire in {remaining} seconds. Do you want to
                extend your session by {msToMin(timeout)} minutes?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  aria-label={`extend session by ${msToMin(timeout)} minutes`}
                  variant="nypl-primary"
                  onClick={() => {
                    activate();
                    setShowModal(false);
                  }}
                >
                  Extend Session
                </Button>
              </Modal.Footer>
            </div>
          </FocusTrap>
        </Modal>
      )}
      {children}
    </AuthContext.Provider>
  );
};

import { ApplicationHeader } from "./ApplicationsList";
import { ViewInfo } from "./ViewApplication";
import { Td } from "../../components/Table/HTable";
import styles from "../global.module.css";

//react bootstrap
import Table from "react-bootstrap/Table";
import Placeholder from "react-bootstrap/Placeholder";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

const BtnPlaceholder = () => (
  <Placeholder as={Td} animation="glow">
    {" "}
    <Placeholder.Button
      xs={6}
      className="btn btn-sm btn-outline-nypl-primary my-auto"
    />
  </Placeholder>
);

const ModalHeader = () => (
  <Modal.Header closeButton>
    <Container>
      <Placeholder animation="glow" as="h2">
        <Placeholder xs={6} />{" "}
      </Placeholder>
    </Container>
  </Modal.Header>
);

export const ApplicationsPlaceholder = () => {
  return (
    <Table hover responsive aria-hidden>
      <ApplicationHeader />
      <tbody>
        {[...Array(4)].map((_, index) => (
          <tr key={index}>
            {[...Array(5)].map((_, index) => (
              <Placeholder key={index} as={Td} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            ))}
            <BtnPlaceholder />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const EditAppPlaceHolder = () => (
  <div aria-hidden>
    <ModalHeader />
    <Modal.Body>
      <h3 className="fs-5 mb-3">Project information</h3>
      <Form.Group
        className={`placeholder-glow mb-3 ${styles.formGroup}`}
        as={Col}
        controlId="project_title"
      >
        <Form.Label className={styles.requiredLabel}>Project Title</Form.Label>
        <Form.Control disabled className="placeholder" type="text" />
      </Form.Group>
      <Form.Group
        className={`placeholder-glow mb-3 ${styles.formGroup}`}
        as={Col}
      >
        <Form.Label className={styles.requiredLabel}>Field of Study</Form.Label>
        <Form.Control disabled className="placeholder" type="text" />
      </Form.Group>
      <Form.Group
        className={`placeholder-glow mb-3 ${styles.formGroup}`}
        as={Col}
      >
        <Form.Label className={styles.requiredLabel}>
          Preferred Length
        </Form.Label>
        <Form.Select disabled className="placeholder" />
      </Form.Group>

      <Form.Group
        className={`placeholder-glow mb-3 ${styles.formGroup}`}
        as={Col}
      >
        <Form.Label className={styles.requiredLabel}>
          Library Division
        </Form.Label>
        <Form.Select disabled className="placeholder" />
      </Form.Group>

      <Form.Group
        className={`placeholder-glow mb-3 ${styles.formGroup}`}
        as={Col}
      >
        <Form.Label className={`text-wrap ${styles.requiredLabel}`}>
          Brief Project Overview (2 or 3 sentences)
        </Form.Label>
        <Form.Control disabled className="placeholder" as="textarea" rows={3} />
      </Form.Group>
      <h3 className="fs-5 mb-3">References</h3>
      <Row className="mb-3">
        <Form.Group className={`placeholder-glow ${styles.formGroup}`} as={Col}>
          <Form.Label className={styles.requiredLabel}>First Name</Form.Label>
          <Form.Control disabled className="placeholder" type="text" />
        </Form.Group>
        <Form.Group className={`placeholder-glow ${styles.formGroup}`} as={Col}>
          <Form.Label className={styles.requiredLabel}>Last Name</Form.Label>
          <Form.Control disabled className="placeholder" type="text" />
        </Form.Group>
      </Row>

      <Form.Group
        className={`placeholder-glow mb-3 ${styles.formGroup}`}
        as={Col}
      >
        <Form.Label className={styles.requiredLabel}>Email</Form.Label>
        <Form.Control disabled className="placeholder" type="text" />
      </Form.Group>
      <Modal.Footer>
        <Placeholder.Button
          className="rounded-pill"
          variant="outline-nypl-danger"
          xs={1}
        />{" "}
        <Placeholder.Button
          className="rounded-pill"
          variant="outline-nypl-primary"
          xs={1}
        />
      </Modal.Footer>
    </Modal.Body>
  </div>
);

export const ViewAppPlaceHolder = () => {
  const TextP = () => (
    <Placeholder animation="glow">
      <Placeholder xs={2} />{" "}
    </Placeholder>
  );
  return (
    <div aria-hidden>
      <ModalHeader />
      <Modal.Body>
        <Row>
          <p>
            <strong>Application Status:</strong> <TextP />
          </p>
        </Row>
        <Row>
          <p>
            <strong>Application Deadline:</strong> <TextP />
          </p>
        </Row>
        <Row>
          <p>
            <strong>Recommendation Letter(s) Status:</strong> <TextP />
          </p>
        </Row>
        <Row>
          <p>
            <strong>Recommendation Letter(s) Deadline:</strong> <TextP />
          </p>
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <p className="my-auto">
            <strong>Letter of recommendation 1</strong>{" "}
            <Placeholder animation="glow">
              <Placeholder bg="danger" xs={4} />{" "}
            </Placeholder>
          </p>
        </Row>
        <ViewInfo content={<TextP />} />
      </Modal.Body>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
//custom styles & components
import styles from "./home/Home.module.css";
import formStyles from "./global.module.css";
import { InvalidFeedback } from "./home/Register/RegistrationComponents";
import { ButtonLoader } from "../components/Loaders";
import { BannerAlert } from "../components/Alerts";
//react bootstrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
//validation
import {
  validateGenericField,
  validatePassword,
  validateConfirmPassword,
  validateEmail,
} from "../helpers/validationHelper";
import { isNullObject } from "../helpers/objectHelpers";
//hooks
import useFetchPayload from "../hooks/useFetchPayload";

export default function ResetPassword() {
  const history = useHistory();

  const [validated, setValidated] = useState(false);

  const { fetchPayload, updateError, isPending, successMessage, response } =
    useFetchPayload();

  const [formStates, setFormStates] = useState({
    accessCode: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  interface FormErrors {
    accessCode: string | null;
    email: string | null;
    newPassword: string | null;
    confirmPassword: string | null;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>({
    accessCode: null,
    email: null,
    newPassword: null,
    confirmPassword: null,
  });

  //validate form values after change
  useEffect(() => {
    const { accessCode, email, newPassword, confirmPassword } = formStates;
    setFormErrors({
      accessCode: validateGenericField("Access Code", accessCode, true, 64),
      email: validateEmail(email),
      newPassword: validatePassword(newPassword),
      confirmPassword: validateConfirmPassword(newPassword, confirmPassword),
    });
  }, [formStates]);

  useEffect(() => {
    (
      document.querySelector("#accessCode") as HTMLObjectElement
    )?.setCustomValidity(formErrors.accessCode || "");
    (document.querySelector("#email") as HTMLObjectElement)?.setCustomValidity(
      formErrors.email || ""
    );
    (
      document.querySelector("#newPassword") as HTMLObjectElement
    )?.setCustomValidity(formErrors.newPassword || "");
    (
      document.querySelector("#confirmPassword") as HTMLObjectElement
    )?.setCustomValidity(formErrors.confirmPassword || "");
  }, [formErrors]);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;

    //if the form is not valid => show validation messages
    if (form.checkValidity() === false) {
      e.stopPropagation();
      const invalidFields = form.querySelectorAll(":invalid");
      const first = invalidFields[0] as HTMLObjectElement;
      alert(first?.validationMessage || "");
      first?.focus();
    } else if (isNullObject(formErrors)) {
      const update_url = process.env.REACT_APP_baseURL + "api/reset-password/";
      formStates.email = formStates.email.toLowerCase();
      fetchPayload({ url: update_url, payload: formStates });
    }
    setValidated(true);
  }

  function handleChange(C: ChangeEvent<HTMLInputElement>) {
    const { id, value } = C.target;
    setFormStates((prev) => ({ ...prev, [id]: value }));
  }

  //if successful password reset => redirect to login
  useEffect(() => {
    if (response?.code === 200)
      history.push({
        pathname: "/home",
        state: { resetPasswordSuccess: response.message },
      });
  }, [response]);

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col className="d-flex justify-content-center">
          <Card className={styles.login_form_banner}>
            <Card.Title as="h2" className="text-center display-6 my-3">
              Reset Password
            </Card.Title>
            <Card.Body>
              {!!updateError && (
                <BannerAlert
                  variant="danger"
                  title="Error resetting password"
                  message={updateError}
                />
              )}
              {!!successMessage && (
                <BannerAlert
                  variant="success"
                  title="Password reset successful"
                  message={successMessage}
                />
              )}

              <Form noValidate onSubmit={onSubmit} id="reset_form">
                {/* Access Code */}
                <Form.Group
                  className={formStyles.formGroup}
                  controlId="accessCode"
                >
                  <Form.Label className={formStyles.requiredLabel}>
                    Access code
                  </Form.Label>
                  <Form.Control
                    {...(validated &&
                      !!formErrors.email && {
                        "aria-describedby": "IFaccess",
                        "aria-invalid": !!formErrors.accessCode,
                      })}
                    aria-describedby="access_msg"
                    value={formStates.accessCode}
                    required
                    placeholder="Access Code"
                    onChange={handleChange}
                    as="textarea"
                    rows={3}
                    spellCheck={false}
                    maxLength={96}
                    autoCorrect="off"
                    onKeyDown={(e) => {
                      //submit form on enter
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        (
                          document.getElementById(
                            "reset_form"
                          ) as HTMLFormElement
                        )?.requestSubmit();
                      }
                    }}
                  />
                  <Form.Text muted id="access_msg">
                    Copy and paste, or type your access code that we sent to
                    your email.
                  </Form.Text>
                  <InvalidFeedback children={formErrors.email} id="IFaccess" />
                </Form.Group>
                {/* email */}
                <Form.Group className={formStyles.formGroup} controlId="email">
                  <Form.Label className={formStyles.requiredLabel}>
                    Email
                  </Form.Label>
                  <Form.Control
                    {...(validated &&
                      !!formErrors.email && {
                        "aria-describedby": "IFemail",
                        "aria-invalid": !!formErrors.email,
                      })}
                    value={formStates.email}
                    required
                    type="email"
                    autoComplete="email"
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  <InvalidFeedback children={formErrors.email} id="IFemail" />
                </Form.Group>
                {/* new password */}
                <Row className="mb-3 justify-content-md-center">
                  <Form.Group
                    className={formStyles.formGroup}
                    as={Col}
                    controlId="newPassword"
                  >
                    <Form.Label className={formStyles.requiredLabel}>
                      New Password
                    </Form.Label>
                    <Form.Control
                      aria-describedby="new_password_invalid"
                      {...(validated &&
                        !!formErrors.newPassword && {
                          "aria-describedby":
                            "IFNewPassword new_password_invalid",
                          "aria-invalid": !!formErrors.newPassword,
                        })}
                      value={formStates.newPassword}
                      required
                      type="password"
                      autoComplete="new-password"
                      placeholder="New password"
                      onChange={handleChange}
                    />
                    <InvalidFeedback
                      children={formErrors.newPassword}
                      id="IFNewPassword"
                    />
                    <Form.Text muted id="new_password_invalid">
                      Password must be at least 8 characters long, contain at
                      least 1 uppercase letter, number, and special character.
                    </Form.Text>
                  </Form.Group>
                </Row>
                {/* confirm new password */}
                <Row className="mb-3 justify-content-md-center">
                  <Form.Group
                    className={formStyles.formGroup}
                    as={Col}
                    controlId="confirmPassword"
                  >
                    <Form.Label className={formStyles.requiredLabel}>
                      Confirm New Password
                    </Form.Label>
                    <Form.Control
                      {...(validated &&
                        !!formErrors.confirmPassword && {
                          "aria-describedby": "IFconfirmPassword",
                          "aria-invalid": !!formErrors.confirmPassword,
                        })}
                      value={formStates.confirmPassword}
                      required
                      type="password"
                      autoComplete="new-password"
                      placeholder="Re-enter new password"
                      onChange={handleChange}
                    />
                    <InvalidFeedback
                      children={formErrors.confirmPassword}
                      id="IFconfirmPassword"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    className="rounded-pill w-75 mx-auto"
                    variant="outline-nypl-primary"
                    type="submit"
                    size="lg"
                  >
                    {isPending ? (
                      <ButtonLoader text="Resetting password" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Row>
                <Row>
                  <Col>
                    <hr aria-hidden />
                  </Col>
                  <div className="col-auto my-auto text-nypl-danger">OR</div>
                  <Col>
                    <hr aria-hidden />
                  </Col>
                </Row>
                <Row>
                  <p className="fw-bold text-center">
                    Already have an account?{" "}
                    <Link className={styles.link} to="/home">
                      Log In
                    </Link>
                  </p>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

import styles from "./skipLink.module.css";
import { Fragment } from "react";

export default function SkipNav({ id = "main-content" }: { id?: string }) {
  return (
    <Fragment>
      <a className={styles.skip_link} href={`#${id}`}>
        Skip to main content
      </a>
      <a className={styles.skip_link} href="//www.nypl.org/accessibility">
        Click to learn about accessibility at the Library
      </a>
    </Fragment>
  );
}

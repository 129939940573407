export function formatDate(date: string): string {
    /*
        converts yyyy-mm-dd to mm/dd/yyy
    */
    const result: Date = new Date(date);
    const year: number = result.getFullYear();
    let month: string = (1 + result.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day: string = result.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return month + '/' + day + '/' + year;
}

export function formatDateForPicker(date: string): string {
    /*
        converts mm/dd/yyyy to yyyy-mm-dd
    */
    const result: Date = new Date(date);
    const year: number = result.getFullYear();
    let month: String = (1 + result.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day: String = result.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return year + '-' + month + '-' + day;
}

let today: Date = new Date();
const thisYear: number = today.getFullYear();
const thisMonth: number = today.getMonth();
const thisDay: number = today.getDate();

//date range for date fields
export const edu_date_range = {
    max: formatDateForPicker(new Date(thisYear + 8, thisMonth, thisDay).toDateString()),
    min: formatDateForPicker(new Date(thisYear - 100, thisMonth, thisDay).toDateString()),
};

export function msToMin(ms: number) {
    return Math.abs(Math.round(ms * 0.000016666666666666667));
}
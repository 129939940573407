import styles from "./table.module.css";
import PortalCard from "../Card";
import { Row, Col } from "react-bootstrap";
import React, { Fragment } from "react";
import { IncompleteWarningAlert } from "../Alerts";

type CardTableProps = {
  className?: string;
  incomplete?: boolean;
  section_title: string;
  side_button?: JSX.Element;
  children: JSX.Element;
  alert: React.ReactNode;
};

function CardTable({
  className = "",
  incomplete = false,
  section_title,
  side_button,
  children,
  alert,
}: CardTableProps) {
  return (
    <PortalCard
      className={`${className} table-responsive row ${
        incomplete && "border-danger"
      } `}
    >
      <Fragment>
        
        <Row>
          <Col className="my-auto">
            <h2 className={`${styles.table_title} my-auto text-nypl-danger`}>
              {section_title}
            </h2>
          </Col>
          <Col md="auto" className="my-auto">
            {side_button}
          </Col>
          {incomplete && <IncompleteWarningAlert />}
          <hr aria-hidden style={{ width: "95%" }} className="mx-auto my-3" />
        </Row>
        {!!alert && alert}
        <table className="table table-hover table-borderless">
          <tbody>{children}</tbody>
        </table>
      </Fragment>
    </PortalCard>
  );
}

type TrProps = {
  head: string;
  data: string;
};

function Tr({ head, data }: TrProps): JSX.Element {
  return (
    <tr>
      <th scope="row" className={styles.table_field}>
        {head}
      </th>
      <td className={styles.table_data}>{data}</td>
    </tr>
  );
}

export { CardTable, Tr };

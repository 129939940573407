import { isValidPhoneNumber } from "react-phone-number-input";
import {
    formatDate,
    edu_date_range,
} from "./dateHelper";

function capitalizeWord(val: string) {
    return val?.charAt(0).toUpperCase() + val?.slice(1);
}

export function validateGenericField(
    fieldName: string,
    fieldValue: string,
    requiredField: boolean = true,
    minLength: number | undefined = undefined,
): string | null {
    if ((!fieldValue || fieldValue.length === 0) && requiredField) {
        return `Please enter ${fieldName}.`;
    } else if (fieldValue?.length > 500) {
        return `${capitalizeWord(fieldName)} must be less than 500 characters.`;
    }
    else if (minLength && fieldValue?.length < minLength) {
        return `${capitalizeWord(fieldName)} must be at least ${minLength} characters.`;
    }
    return null;
}

export function validateEmail(fieldValue: string, fieldName?: string): string | null {
    let filter: RegExp =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (fieldValue && !filter.test(fieldValue)) {
        return `Please enter a valid ${fieldName || "email"}.`;
    } else if (fieldValue?.length > 100) {
        return `${fieldName || "email"} must be less than 100 characters.`;
    } else if (!capitalizeWord(fieldValue) || fieldValue?.length === 0) {
        return `Please enter ${fieldName || "email"}.`;
    }
    return null;
}

export function validatePassword(fieldValue: string): string | null {
    if (!fieldValue || fieldValue.length === 0) {
        return "Please enter a password.";
    } else if (fieldValue.length < 8) {
        return "Password must be at least 8 characters.";
    }
    //if password doesn't have special characters
    else if (fieldValue.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) === -1) {
        return "Password must contain at least one special character.";
    }
    //if password doesn't have numbers
    else if (fieldValue.search(/\d/) === -1) {
        return "Password must contain at least one number.";
    }
    //if password doesn't have uppercase letters
    else if (fieldValue.search(/[A-Z]/) === -1) {
        return "Password must contain at least one uppercase letter.";
    }
    return null;
}

export function validateSelector(
    fieldName: string,
    fieldValue: string
): string | null {
    if (!fieldValue || fieldValue.length === 0) {
        return `Please select ${fieldName}.`;
    }
    return null;
}

export function validateConfirmPassword(
    password: string,
    confirmPassword: string
): string | null {
    if (!confirmPassword || confirmPassword.length === 0) return "Please confirm your password.";
    else if (validatePassword(password) !== null) return validatePassword(password);
    else if (password !== confirmPassword) {
        return "Passwords do not match.";
    }
    return null;
}

export function validatePhoneNumber(
    phoneNum: string,
    dialCode?: string
): string | null {
    const baseMessage: string = "Please enter a valid phone number.";
    if (!phoneNum || phoneNum.length === 0 || phoneNum === "+") {
        return "Please enter your phone number.";
    }
    if (dialCode && !isValidPhoneNumber(phoneNum, { defaultCallingCode: dialCode })) {
        return baseMessage;
    }
    else if (!dialCode && !isValidPhoneNumber(phoneNum)) {
        if (phoneNum.charAt(0) === "+")
            return baseMessage;
        phoneNum = "+" + phoneNum;
        validatePhoneNumber(phoneNum);
    }
    return null;
}

export function validateSelect(
    fieldValue: string,
    fieldName: string | null = null
): string | null {
    return !fieldValue ? `Please select ${fieldName ? `a ${fieldName}.` : "an option."}` : null;
}

export function validateArrayOfObj(
    fieldName: string | null = null,
    fieldValue: Array<any>

) {
    return !fieldValue.length ? `Please select ${fieldName ? `${fieldName}.` : "an option."}` : null;
}

export function validateDateField(date: string, fieldName: string, date_range: boolean = false): string | null {
    const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!date) {
        return `Please enter a ${fieldName ? `${fieldName}` : "date"} in the form mm/dd/yyyy.`;
    }
    else if (!pattern.test(date)) {
        return `Please enter a valid ${fieldName ? `${fieldName}` : "date"} in the form mm/dd/yyyy.`;
    }
    else if (date_range) {
        
        const year = date.split("/")[2];
        
        const max_year = edu_date_range.max.split("-")[0];
        const min_year = edu_date_range.min.split("-")[0];

        if (year > max_year || year < min_year) {
            return `Please enter a date within ${formatDate(edu_date_range.min)} - ${formatDate(edu_date_range.max)}`;
        }

        const parsed_date = new Date(date);
        if (parsed_date > new Date(edu_date_range.max) || parsed_date < new Date(edu_date_range.min)) {
            return `Please enter a date within ${formatDate(edu_date_range.min)} - ${formatDate(edu_date_range.max)}`;
        }
    }
    return null;
}


export function validateCV(input: any[]): string | null {
    if (input.length !== 1) {
        if (input.length > 0) return "Please only upload one file.";
        else return "Please upload a file.";
    }
    else {
        const upload_file = input[0];
        //if the file type is not accepted => throw error
        if (!upload_file.name.match(/\.(pdf|doc|docx|jpg|jpeg|png|txt)$/)) {
            return "File type is not supported, please upload a pdf, doc/docx, jpg/jpeg, png or txt file.";
        }

        //if the file size is less than 10MB => throw an error
        if (upload_file.size > 10000000) {
            return "File size is too large, must be less than 10MB.";
        }
    }
    return null;
}

export function validatePDF(input: any[], exception?: boolean): string | null {
    if (exception) return null;

    if (input.length !== 1) {
        if (input.length > 0) return "Please only upload one file.";
        else return "Please upload a file.";
    }
    else {
        const upload_file = input[0];
        if (!upload_file?.name) {
            return "Please upload a file.";
        }
        //if the file type is not accepted => throw error
        if (!upload_file?.name.match(/\.(pdf)$/)) {
            return "File type is not supported, please upload a pdf file.";
        }

        //if the file size is less than 10MB => throw an error
        if (upload_file.size > 10000000) {
            return "File size is too large, must be less than 10MB.";
        }
    }
    return null;
}
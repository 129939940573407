import { Fragment } from "react";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";

export function RowLoader({ className = "" }) {
  return (
    <tr>
      <td
        colSpan={100}
        style={{ textAlign: "center", verticalAlign: "middle" }}
      >
        <Spinner
          className={`${className} mx-auto my-2`}
          as="span"
          animation="grow"
          role="status"
          aria-hidden="true"
        />
      </td>
    </tr>
  );
}

export function ButtonLoader({ text }: { text: string }) {
  return (
    <Fragment>
      <Spinner
        className="mx-1 my-auto"
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      {text + "..."}
    </Fragment>
  );
}

export const CardFallback = (
  <Card>
    <Spinner
      className="mx-auto my-4"
      as="span"
      animation="grow"
      role="status"
      aria-hidden="true"
    />
  </Card>
);

export const HomeFallback = (
  <Placeholder as="p" animation="glow" className="text-center">
    <Placeholder xs={6} /> <Placeholder xs={3} bg="danger" />
  </Placeholder>
);

export const PortalCardFallback = (
  <Card className="shadow-lg m-1 ">
    <Spinner
      className="mx-auto my-4"
      as="span"
      animation="grow"
      role="status"
      aria-hidden="true"
    />
  </Card>
);

export const BtnFallback = () => (
  <Placeholder.Button
    animation="wave"
    variant="dark"
    className="rounded-pill h-100"
    sm={6}
  />
);

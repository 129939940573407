/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useAuthContext } from "./useAuthContext";

//! this method allows for the fetching of data from the datastore and requires the user to be logged in

const useFetchDatastore = (url: string, objPayload?: Record<string, any>) => {
  //const [data, setData] = useState( null);
  const [data, setData] = useState<any>([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuthContext();
  const userEmail = user.displayName;

  useEffect(() => {
    // to run the json server run the following command npx json-server --watch data/db.json --port 8000

    const abortCont = new AbortController();
    user.getIdToken(true).then((token: string) => {
      //fetch api
      const userPayload = {
        ...objPayload,
        user_email: userEmail,
      };
      fetch(
        url,
        {
          signal: abortCont.signal,
          method: "post",

          headers: {
            Accept: "application/json",
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userPayload),
        },
      )
        .then((res) => {
          if (!res.ok) {
            res
              .clone()
              .text()
              .then((err) => {
                let error_obj = JSON.parse(err);
                if (error_obj.message) {
                  setError(error_obj.message);
                } else {
                  setError(
                    "There was an error updating your information. Please try again later."
                  );
                }
              }).catch((e) => {
                setIsPending(false);
                setError(e?.toString() || "There was an error fetching your data. Please try again later.");
              });
          }
          setIsPending(false);
          return res.json();
        })
        .then((data) => {
          // console.log("Fetch datastore data:", data.data); //todo: remove this for production
          setData(data.data);
        })
        .catch((e) => {
          if (e.name === "AbortError") {
            console.log("fetch aborted");
          } else {
            setIsPending(false);
            setError(e.message);
          }
        });
    }).catch((e: any) => {
      setIsPending(false);
      setError(e?.toString() || "There was an error fetching your data. Please try again later.");
    });

    //stop the fectch if the user click on different page
    return () => abortCont.abort();
  }, [url]);

  return { data, isPending, error };
};

export default useFetchDatastore;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
//react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
//custom components & styles
import styles from "./Home.module.css";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { ButtonLoader, HomeFallback } from "../../components/Loaders";
import { BannerAlert } from "../../components/Alerts";
import { InvalidFeedback } from "./Register/RegistrationComponents";
//hooks
import useLoginToken from "../../hooks/useLogintoken";
import useLogout from "../../hooks/useLogout";
//helpers
import { validateEmail } from "../../helpers/validationHelper";
//lazy loading
const RegisterModal = lazy(() => import("./Register/RegisterModal"));
const ForgotPassword = lazy(() => import("./ForgotPassword/ForgotPassword"));

export default function Home() {
  interface LocationState {
    resetPasswordSuccess?: string;
  }
  const location = useLocation<LocationState>();

  //states
  const [showCompleteReg, setShowCompleteReg] = useState<boolean>(
    sessionStorage.getItem("completedRegistration") === "true"
  );
  const [showRegister, setShowRegister] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [showResetPasswordSuccess, setShowResetPasswordSuccess] =
    useState(false);

  const deleteSucessMsg = sessionStorage.getItem("delete_success");
  interface FormErrors {
    email: string | null;
    password: string | null;
  }
  const [formErrors, setFormErrors] = useState<FormErrors>({
    email: null,
    password: null,
  });

  //login hook
  const {
    login,
    error: LoginError,
    isPending: loginPending,
    // data,
  } = useLoginToken();
  //logout hook
  const { logout, error: logoutError, isPending: logoutPending } = useLogout();

  function handleLoginSubmit(e: React.FormEvent<HTMLFormElement>) {
    //submit login form
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      const invalidFields = form.querySelectorAll(":invalid");
      const first = invalidFields[0] as HTMLObjectElement;
      if (first.id === "password_field") {
        setFormErrors({
          ...formErrors,
          password: first.validationMessage,
        });
      }
      alert(first.validationMessage);
      first.focus();
    } else {
      login(email, password);
    }
    setValidated(true);
  }

  //!Effects
  // show success alert if the user user has reset password successfully
  useEffect(() => {
    if (!!location.state?.resetPasswordSuccess)
      setShowResetPasswordSuccess(true);
  }, [location.pathname]);

  //! remove all local storage items on component unmount
  useEffect(() => {
    if (sessionStorage.getItem("loggedOut") === "true") {
      window.location.reload();
      sessionStorage.removeItem("loggedOut");
    }
    return () => {
      sessionStorage.clear();
      window.history.replaceState({}, document.title);
    };
  }, []);

  //validate email on change
  useEffect(() => {
    setFormErrors({
      ...formErrors,
      email: validateEmail(email),
    });
  }, [email]);
  //set custom validity for email
  useEffect(() => {
    (
      document.getElementById("email_field") as HTMLObjectElement
    )?.setCustomValidity(formErrors.email || "");
  }, [formErrors]);

  //! close modal if successful logout
  useEffect(() => {
    if (!logoutPending && !logoutError) {
      setShowRegister(false);
    }
  }, [logoutPending, logoutError]);

  //! this page is using BootStrap 5
  return (
    <Container fluid>
      <Row className="justify-content-between">
        {/* Introduction card */}
        <Col className="d-flex justify-content-center my-auto">
          <Card className={`${styles.welcome_banner} text-white`}>
            <Card.Body>
              <h1 className="card-title text-center">
                {" "}
                NYPL Fellowship Portal
              </h1>
              <hr aria-hidden />
              <Card.Text className="lead">
                Welcome to the NYPL Fellowship Portal. A number of the Library’s
                fellowships use this central portal to manage applications. Once
                you have created an account, you will be able to apply for the
                fellowships currently listed in the portal. <br />
                <br />
                Please also visit our full{" "}
                <a
                  className="link-light"
                  href="https://www.nypl.org/help/about-nypl/fellowships-institutes"
                >
                  Fellowships and Institutes
                </a>{" "}
                page for further details on individual fellowship opportunities
                at the Library, including relevant dates and application
                processes.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Login Form */}
        <Col className="d-flex justify-content-center">
          <Card className={styles.login_form_banner}>
            <Card.Body>
              {showResetPasswordSuccess && (
                <BannerAlert
                  variant="success"
                  message={location.state?.resetPasswordSuccess}
                />
              )}
              {!!deleteSucessMsg && (
                <BannerAlert
                  variant="success"
                  message={deleteSucessMsg}
                  removeAlert={() =>
                    sessionStorage.removeItem("delete_success")
                  }
                />
              )}
              {showCompleteReg && (
                <BannerAlert
                  variant="success"
                  title="Registration Complete"
                  message="You can now login to your account"
                  removeAlert={() => {
                    setShowCompleteReg(false);
                    sessionStorage.setItem("completedRegistration", "false");
                  }}
                />
              )}
              {LoginError && (
                <BannerAlert
                  variant="danger"
                  title="Failed to Login"
                  message={LoginError}
                />
              )}
              <h2 className="card-title text-center">Login to your account</h2>
              <hr aria-hidden />
              <Form
                onSubmit={handleLoginSubmit}
                noValidate
                validated={validated}
              >
                <Form.Group
                  className={`${styles.form_group} mb-3`}
                  controlId="email_field"
                >
                  <Form.Label className={styles.login_label}>
                    Email Address
                  </Form.Label>
                  <Form.Control
                    required
                    aria-required="true"
                    type="email"
                    autoComplete="email"
                    className="form-control"
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    value={email}
                    {...(validated &&
                      !!formErrors.email && {
                        "aria-invalid": !!formErrors.email,
                        "aria-describedby": "email_error",
                      })}
                  />
                  <InvalidFeedback id="email_error">
                    {formErrors.email}
                  </InvalidFeedback>
                </Form.Group>
                <Form.Group
                  className={`${styles.form_group} mb-3`}
                  controlId="password_field"
                >
                  <Form.Label className={styles.login_label}>
                    Password
                  </Form.Label>
                  <Form.Control
                    required
                    aria-required="true"
                    type="password"
                    autoComplete="current-password"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    {...(validated &&
                      !!formErrors.password && {
                        "aria-invalid": !!formErrors.password,
                        "aria-describedby": "password_error",
                      })}
                  />
                  <InvalidFeedback id="password_error">
                    {formErrors.password}
                  </InvalidFeedback>
                </Form.Group>
                <Row className="justify-content-center">
                  <Button
                    type="submit"
                    variant="outline-nypl-primary"
                    size="lg"
                    className={`rounded-pill ${styles.submit_button}`}
                  >
                    {!loginPending
                      ? "Log In"
                      : ButtonLoader({ text: "Logging in" })}
                  </Button>
                </Row>
              </Form>
              <br />

              {/* forgot password and register links */}
              {/*
              //todo: forgot password
              */}
              <Suspense fallback={HomeFallback}>
                <ForgotPassword
                  show={showForgotPassword}
                  onHide={() => setShowForgotPassword(false)}
                />
                <Row>
                  <p className="text-center">
                    Forgot your password?{" "}
                    <Button
                      variant="link"
                      className={`${styles.link}`}
                      onClick={() => setShowForgotPassword(true)}
                    >
                      Reset Password
                    </Button>
                  </p>
                </Row>
              </Suspense>
              <Row>
                <Col>
                  <hr aria-hidden />
                </Col>
                <div className="col-auto my-auto text-nypl-danger">OR</div>
                <Col>
                  <hr aria-hidden />
                </Col>
              </Row>
              <Suspense fallback={HomeFallback}>
                <RegisterModal
                  show={showRegister}
                  onHide={() => logout(false)}
                  onSuccess={() => {
                    setShowCompleteReg(
                      sessionStorage.getItem("completedRegistration") === "true"
                    );
                    window.location.reload();
                  }}
                />
                <Row>
                  <p className="fw-bold text-center">
                    Don't have an account?{" "}
                    <Button
                      variant="link"
                      className={styles.link}
                      onClick={() => setShowRegister(true)}
                    >
                      Register Now
                    </Button>
                  </p>
                </Row>
              </Suspense>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loginPending && <LoadingScreen />}
    </Container>
  );
}

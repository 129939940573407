import styles from "./LoadingScreen.module.css";

export default function LoadingScreen(): JSX.Element {
  return (
    <div>
      <div className={styles.loading}>
        <div className={styles.uil_ring_css}>
          <div></div>
        </div>
      </div>
    </div>
  );
}

import NyplHeader from "../../components/NyplHeader";
import NyplFooter from "../../components/Footer/NyplFooter";
import styles from "./HomeDefault.module.css";
import { Fragment } from "react";

export default function HomeDefault(props: { children: React.ReactNode }) {
  return (
    <Fragment>
      <NyplHeader />
      <div className={styles.home_body} id="main-home-content">
        {props.children}
      </div>
      <NyplFooter />
    </Fragment>
  );
}

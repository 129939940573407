
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

// Your web app's Firebase configuration
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FirebaseAPIKey,
  authDomain: process.env.REACT_APP_FirebaseAuthDomain,
  projectId: process.env.REACT_APP_FirebaseProjectId,
  storageBucket: process.env.REACT_APP_FirebaseStorageBucket,
  messagingSenderId: process.env.REACT_APP_FirebaseMessagingSenderId,
  appId: process.env.REACT_APP_FirebaseAppId,
  measurementId: process.env.REACT_APP_FirebaseMeasurementId
})

// init services
const projectAuth = firebase.auth()
//time stamp 

export { projectAuth }
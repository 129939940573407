import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useLogin } from "./useLogin";

const useUpdateUserInfo = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const { user } = useAuthContext();
  const [updateData, setUpdateData] = useState<any>(null);
  const { isPending: fire_isPending } = useLogin();
  const userEmail = user.displayName;

  const updateInfo = async (url: string, UpdateObj: Record<string, any>) => {
    setIsUpdatePending(true);
    const payload = { ...UpdateObj, user_email: userEmail };

    user.getIdToken(true).then((token: string) => {
      try {
        const abortCont = new AbortController();

        const userPayload = payload;

        fetch(
          url,
          {
            signal: abortCont.signal,
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userPayload),
          },
        )
          .then((res) => {
            if (!res.ok) { //if the response is not valid
              //parse response as object and throw error
              res.text().then((err) => {
                let error_obj = JSON.parse(err);
                if (error_obj.message) {
                  setUpdateError(error_obj.message);
                }
                else {
                  setUpdateError("There was an error updating your information. Please try again later.");
                }
              }).catch((e) => {
                // console.log(e);
                setUpdateError(e?.toString() || "There was an error updating your information. Please try again later.");
                setIsUpdatePending(false);
              });
              setIsUpdatePending(false);
            }
            return res.json();
          })
          .then((data) => {
            if (!fire_isPending) {
              setUpdateData(data);
              setIsUpdatePending(false);
              setUpdateError("");
            }
          })
          .catch((e) => {
            if (e.name === "AbortError") {
              console.log("fetch aborted");
            } else {
              setIsUpdatePending(false);
              setUpdateError(e.message);
            }
          });
        return () => abortCont.abort();

        //update state
      } catch (err: any) {
        if (!isCancelled) {
          console.log(err.message);
          setUpdateError(err.message);
          setIsUpdatePending(false);
        }
      }
    }).catch((e: any) => {
      setIsUpdatePending(false);
      setUpdateError(e?.toString() || "There was an error updating your information. Please try again later.");
    });
  };
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { updateInfo, updateError, isUpdatePending, updateData };
};

export default useUpdateUserInfo;

import { ColProps, Col, Row, Image } from "react-bootstrap";
import PortalCard from "../../components/Card";
import logo from "../.././assets/img/logo.jpeg";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";

// This file contains common components used in the Fellowships pages.

export const Detail = (
  name: string,
  value: string,
  md: ColProps["md"] = undefined
) => (
  <Col className="mt-2" md={md}>
    <p>
      <span className="fw-bold text-nypl-danger">
        <FontAwesomeIcon icon={faCertificate} /> {`${name}: `}
      </span>
      {value}
    </p>{" "}
  </Col>
);

export const Logo = () => (
  <Image
    roundedCircle
    src={logo}
    alt="The New York Public Library logo"
    width="45"
    height="45"
  />
);

const HeaderPlaceholder = () => (
  <div className="d-flex flex-row">
    <div className="d-flex my-auto">
      <Logo />
    </div>
    <div className="col ps-4">
      <Row>
        <p className="placeholder-glow">
          <span className="placeholder col-8"></span>
        </p>
      </Row>
      <Row>
        <p className="placeholder-glow">
          <span className="placeholder col-4"></span>
        </p>
      </Row>
    </div>
  </div>
);

const DescriptionPlaceholder = () => (
  <Row className="my-2">
    <p className="placeholder-glow">
      <span className="placeholder col-8"></span>
    </p>
    <p className="placeholder-glow">
      <span className="placeholder col-10"></span>
    </p>
    <p className="placeholder-glow">
      <span className="placeholder col-6"></span>
    </p>
  </Row>
);

const DetailsPlaceholder = () => (
  <Fragment>
    <span className="placeholder placeholder-sm col-3 bg-danger"></span>
    <span className="placeholder placeholder-sm col-3 bg-danger"></span>
    <span className="placeholder placeholder-sm col-3 bg-danger"></span>
  </Fragment>
);

export const FLPlaceholder = () => (
  <Row>
    <PortalCard>
      <HeaderPlaceholder />
      <DescriptionPlaceholder />
      <p className="row placeholder-glow justify-content-between">
        <DetailsPlaceholder />
        <button className="btn btn-outline-nypl-primary rounded-pill disabled placeholder col-1"></button>
      </p>
    </PortalCard>
  </Row>
);

export const FellowshipDetailPlaceholder = () => (
  <Row>
    <PortalCard>
      <HeaderPlaceholder />
      <Row className="mx-auto">
        <p className="row placeholder-glow justify-content-between">
          <DetailsPlaceholder />
        </p>
        <p className="row placeholder-glow justify-content-between">
          <DetailsPlaceholder />
        </p>
      </Row>
      <DescriptionPlaceholder />
    </PortalCard>
  </Row>
);

export const FLHeader = ({
  fellowship_name,
  term,
}: {
  fellowship_name: string;
  term: string;
}) => (
  <div className="d-flex flex-row">
    <div className="d-flex my-auto">
      <Logo />
    </div>
    <div className="ps-4">
      <Row>
        <h2 className="card-title text-nypl-danger lead">{fellowship_name}</h2>
      </Row>
      <Row>
        <p className="card-text fw-bold">{term}</p>
      </Row>
    </div>
  </div>
);


import { useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";

export default function useDownload() {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [downloadPending, setDownloadPending] = useState(false);
  const [previewPending, setPreviewPending] = useState(false);
  const { user } = useAuthContext();

  async function downloadFile(cvKey: string, FileName: string, action = "download") {
    setError(null);

    switch (action) {
      case "preview":
        setPreviewPending(true);
        break;
      default:
        setDownloadPending(true);
    }

    //try to sign the user out
    try {
      const url = process.env.REACT_APP_baseURL + "api/download-cv/";
      const abortCont = new AbortController();
      user.getIdToken(true).then((token: string) => {
        //fetch api
        const userPayload = {
          imageURL: cvKey,
        };
        fetch(
          url,
          {
            method: "post",
            signal: abortCont.signal,
            headers: {
              Accept: "application/json",
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userPayload),
          }
        )
          .then((res) => {
            if (!res.ok) {
              //if the response is not valid
              //parse response as object and throw error
              res.text().then((err) => {
                let error_obj = JSON.parse(err);
                // console.log("error_obj", error_obj);
                if (error_obj.message) {
                  setError(error_obj.message);
                } else {
                  setError(
                    "There was an error downloading your file. Please try again later."
                  );
                }
              }).catch((e) => {
                throw new Error(e);
              }
              );
              switch (action) {
                case "preview":
                  setPreviewPending(false);
                  break;
                default:
                  setDownloadPending(false);
              }
            }
            //return res.json()
            return res.blob();
          })
          .then((file) => {
            let blob = new Blob([file], { type: file.type });
            // Create an object URL for the blob object
            const url = URL.createObjectURL(blob);
            switch (action) {
              case "preview":
                window.open(url, "_blank");
                break;
              default:
                const a = document.createElement("a");
                a.href = url;
                a.download = FileName || "download";
                a.click();
                a.parentNode?.removeChild(a);
            }
            setError(null);
          })
          .catch((e) => {
            if (e.name === "AbortError") {
              console.log("fetch aborted");
            } else {
              console.error(e.message);
              setError(e.message);
            }
          }).finally(() => {
            setDownloadPending(false);
            setPreviewPending(false);
          });
      }).catch((err: any) => {
        console.log(err);
        setDownloadPending(false);
        setPreviewPending(false);
        setError(err?.toString() || "there was an error sending the request");
      });

      //stop the fectch if the user click on different page
      return () => abortCont.abort();
    } catch (err: any) {
      if (!isCancelled) {
        console.log(err.message);
        setError(err.message);
        setDownloadPending(false);
      }
    }
  }
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { downloadFile, error, downloadPending, previewPending };
}
